/* eslint-disable */
import React from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import {
  useOktaAuth,
  Security,
  SecureRoute,
  LoginCallback,
} from "@okta/okta-react";
import config from "./config";
import CorsErrorModal from "./CorsErrorModal";
import Menu from "./components/pages/menu/index.jsx";
import Pages from "./components/layout/Pages";
import Profile from "./Profile";
import LiveBedCapacity from "./components/pages/livebedcapacity/LiveBedCapacity";
import NotFound from "./components/Alerts/NotFound";
import { makeStyles } from "@material-ui/core/styles";
import AdminAuth from "./components/adminPanel/AdminAuth";
import ContactUs from "./components/pages/Support/ContactUs";
import Adt from "./components/pages/adt/Adt";
import AdtOccupancyByHospital from "./components/pages/adt/AdtOccupancyByHospitals";
import LabsSummaryCopy from "./components/pages/labsSummaryCopy/LabsSummary";
import Covid19 from "./components/pages/covid19/Covid19";
import Capacity from "./components/pages/capacityforecasting/Capacity";
import Ppe from "./components/pages/ppedashboard/Ppe";
import Reports from "./components/pages/reports/Reports";
import AdHocReports from "./components/pages/adhocreports/AdHocReports";
import HospitalsMap from "./components/pages/livebedcapacity/HospitalsMap";


const oktaAuth = new OktaAuth(config.oidc);

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  welcomeBlock: {
    width: 500,
    height: 100,
    padding: 50,
  },
  root: {
    display: "flex",
    height: "100%",
    background: "#f5f7fa",
  },
  footerGrid: {
    position: "relative",
  },
}));

const publicPages = [
  {
    path: "/login/callback",
    component: LoginCallback,
    layout: Pages,
  },
  {
    path: "/",
    component: LoginCallback,
    layout: Pages,
  }
];

const securePages = [
  {
    path: "/bedcapacity",
    component: LiveBedCapacity,
    layout: Pages,
    viewPath: "",
    viewHeight: 1000,
  },
  {
    path: "/ia-hospital-locator",
    component: HospitalsMap,
    layout: Pages,
    viewPath: "",
    viewHeight: 1000,
  },
  {
    path: "/labs",
    component: LabsSummaryCopy ,
    layout: Pages,
    viewPath: "",
    viewHeight: 1000,
  },
  {
    path: "/adtdashboard",
    component: Adt,
    layout: Pages,
    viewPath: "",
    viewHeight: 1000,
  },
  {
    path: "/covid19patients",
    component: Covid19,
    layout: Pages,
    viewPath: "",
    viewHeight: 1000,
  },
  {
    path: "/capacityforecasting",
    component: Capacity,
    layout: Pages,
    viewPath: "",
    viewHeight: 1000,
  },
  {
    path: "/ppedashboard",
    component: Ppe,
    layout: Pages,
    viewPath: "",
    viewHeight: 1000,
  },
  {
    path: "/reports",
    component: Reports,
    layout: Pages,
    viewPath: "",
    viewHeight: 1000,
  },
  {
    path: "/adhocreports",
    component: AdHocReports,
    layout: Pages,
    viewPath: "",
    viewHeight: 1000,
  },
  {
    path: "/menu",
    component: Menu,
    layout: Pages,
    viewPath: "",
    viewHeight: 1000,
  },
  {
    path: "/profile",
    component: Profile,
    layout: Pages,
    viewPath: "",
    viewHeight: "auto",
  },
  {
    path: "/notfound",
    component: NotFound,
    layout: Pages,
    viewPath: "",
    viewHeight: "auto",
  },
  {
    path: "/admin",
    component: AdminAuth,
    layout: Pages,
    viewPath: "",
    viewHeight: "auto",
  },
  {
    path: "/contactus",
    component: ContactUs,
    layout: Pages,
    viewPath: "",
    viewHeight: "auto",
  }
];

const App = () => {
  const [corsErrorModalOpen, setCorsErrorModalOpen] = React.useState(false);
  const history = useHistory();
  const classes = useStyles();
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <div className="App">
        <CorsErrorModal {...{ corsErrorModalOpen, setCorsErrorModalOpen }} />
        <Switch>
          <>
            {securePages.map(
              (
                {
                  path,
                  viewPath,
                  viewHeight,
                  component: Component,
                  layout: Layout,
                },
                index
              ) => (
                <SecureRoute
                  key={index}
                  path={path}
                  render={(props) => (
                    <Layout {...props}>
                      <Component
                        {...props}
                        viewPath={viewPath}
                        viewHeight={viewHeight}
                      />
                    </Layout>
                  )}
                />
              )
            )}
            {publicPages.map(
              ({ path, component: Component, layout: Layout }, index) => (
                <Route
                  key={index}
                  path={path}
                  exact
                  render={(props) => (
                    <Layout {...props}>
                      <Component {...props} />
                    </Layout>
                  )}
                />
              )
            )}
          </>
        </Switch>
      </div>
    </Security>
  );
};

export default App;
