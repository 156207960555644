import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { Place } from "@material-ui/icons";
import React, { Component } from "react";

export default class HospitalsMap extends Component {
  componentDidMount() {
    let config = {
      selector: ".storerocket-store-locator",
      account: "Or85WZk4NM",
    };

    StoreRocket.init(config);
  }
  render() {
    return (
      <div
        className="store-locator"
        style={{ marginTop: "50px", marginBottom: "5px" }}
      >
        <List style={{ color: "#2F5E8C" }}>
          <ListItem>
            <ListItemIcon>
              <Place fontSize="large" style={{ color: "#2F5E8C" }} />
            </ListItemIcon>

            <ListItemText>
              <div
                className="d-inline"
                style={{
                  marginLeft: "-18px",
                  fontWeight: "bold",
                  fontSize: "30px",
                }}
              >
                <span>Beds Locator</span>
              </div>
            </ListItemText>
          </ListItem>
        </List>
        <div
          style={{ height: "85vh" }}
          className="storerocket-store-locator"
        ></div>
      </div>
    );
  }
}
