import React, { useEffect } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import highchartsAccessibility from "highcharts/modules/accessibility";
import exporting from "highcharts/modules/exporting";
import indicators from "highcharts/indicators/indicators";
import regressions from "highcharts/indicators/regressions";
import trendline from "highcharts/indicators/trendline";

indicators(Highcharts);
regressions(Highcharts);
// import HC_Regression from  "highcharts-regression";
trendline(Highcharts);
exporting(Highcharts);
// HC_Regression(Highcharts);
highchartsAccessibility(Highcharts);

export default function LabsOrderedVsCompleted({data, dataType }) {

  const data1 = data.sort((a, b) => a.Date - b.Date);
  const result1 = data1.map(({ TotalLabsOrderedRsv, Date }) => [
    parseFloat(Date + "000"),
    parseFloat(TotalLabsOrderedRsv),
  ]);
  const result11 = data1.map(({ TotalLabsCompletedRsv, Date }) => [
    parseFloat(Date + "000"),
    parseFloat(TotalLabsCompletedRsv),
  ]);

  const result2 = data1.map(({ TotalLabsOrderedInfluenza, Date }) => [
    parseFloat(Date + "000"),
    parseFloat(TotalLabsOrderedInfluenza),
  ]);
  const result22 = data1.map(({ TotalLabsCompletedInfluenza, Date }) => [
    parseFloat(Date + "000"),
    parseFloat(TotalLabsCompletedInfluenza),
  ]);
  const result3 = data1.map(({ TotalLabsOrderedCovid, Date }) => [
    parseFloat(Date + "000"),
    parseFloat(TotalLabsOrderedCovid),
  ]);
  const result33 = data1.map(({ TotalLabsCompletedCovid, Date }) => [
    parseFloat(Date + "000"),
    parseFloat(TotalLabsCompletedCovid),
  ]);
  useEffect(() => {
    seriesDecided(dataType);
  }, [dataType, data]);

  const rsvLine = [
    {
      id: "rsvOrdered",
      name: "RSV Ordered",
      data: result1,
      color: "#926611",
      tooltip: {
        valueDecimals: 0,
      },
      marker: {
        enabled: true,
        radius: 3,
      },
    },
    {
      type: "trendline",
      linkedTo: "rsvOrdered",
      zIndex: -1,
      color: "#f9d58e",
      marker: {
        enabled: false,
      },
      tooltip: { enabled: false },
      enableMouseTracking: false,
      dashStyle: "longdash",
    },
    {
      name: "RSV Completed",
      id: "rsvCompleted",
      data: result11,
      color: "#f3aa1c",
      tooltip: {
        valueDecimals: 0,
      },
      marker: {
        enabled: true,
        radius: 3,
      },
    },
    {
      type: "trendline",
      linkedTo: "rsvCompleted",
      zIndex: -1,
      color: "#f9d58e",
      marker: {
        enabled: false,
      },
      tooltip: { enabled: false },
      enableMouseTracking: false,
      dashStyle: "longdash",
    },
  ];

  const influLine = [
    {
      name: "Influenza Ordered",
      data: result2,
      id: "influenzaOrdered",
      color: "#4f7327",
      tooltip: {
        valueDecimals: 0,
      },
      marker: {
        enabled: true,
        radius: 3,
      },
    },
    {
      type: "trendline",
      linkedTo: "influenzaOrdered",
      zIndex: -1,
      color: "#cde5b3",
      marker: {
        enabled: false,
      },
      tooltip: { enabled: false },
      enableMouseTracking: false,
      dashStyle: "longdash",
    },
    {
      name: "Influenza Completed",
      id: "influenzaCompleted",
      data: result22,
      color: "#83bf41",
      tooltip: {
        valueDecimals: 0,
      },
      marker: {
        enabled: true,
        radius: 3,
      },
    },
    {
      type: "trendline",
      linkedTo: "influenzaCompleted",
      zIndex: -1,
      color: "#cde5b3",
      marker: {
        enabled: false,
      },
      tooltip: { enabled: false },
      enableMouseTracking: false,
      dashStyle: "longdash",
    },
  ];

  const covidLine = [
    {
      name: "Covid Ordered",
      id: "covidOrdered",
      data: result3,
      color: "#056982",
      tooltip: {
        valueDecimals: 0,
      },
      marker: {
        enabled: true,
        radius: 3,
      },
    },
    {
      type: "trendline",
      linkedTo: "covidOrdered",
      zIndex: -1,
      color: "#b5e7f4",
      marker: {
        enabled: false,
      },
      tooltip: { enabled: false },
      enableMouseTracking: false,
      dashStyle: "longdash",
    },
    {
      name: "Covid Completed",
      id: "covidCompleted",
      data: result33,
      color: "#09afd9",
      tooltip: {
        valueDecimals: 0,
      },
      marker: {
        enabled: true,
        radius: 3,
      },
    },
    {
      type: "trendline",
      linkedTo: "covidCompleted",
      zIndex: -1,
      color: "#b5e7f4",
      marker: {
        enabled: false,
      },
      tooltip: { enabled: false },
      enableMouseTracking: false,
      dashStyle: "longdash",
    },
  ];
  const seriesDecided = (dataType) => {
    // chart && chart.destroy()
    let seriesFin = [];
    if (
      dataType.indexOf("Rsv") > -1 &&
      dataType.indexOf("Influenza") < 0 &&
      dataType.indexOf("Covid") < 0
    ) {
      seriesFin = rsvLine.map((a) => a);
    }

    if (
      dataType.indexOf("Influenza") > -1 &&
      dataType.indexOf("Rsv") < 0 &&
      dataType.indexOf("Covid") < 0
    ) {
      seriesFin = influLine.map((a) => a);
    }
    if (
      dataType.indexOf("Covid") > -1 &&
      dataType.indexOf("Influenza") < 0 &&
      dataType.indexOf("Rsv") < 0
    ) {
      seriesFin = covidLine.map((a) => a);
    }
    if (
      dataType.indexOf("Covid") > -1 &&
      dataType.indexOf("Influenza") > -1 &&
      dataType.indexOf("Rsv") > -1
    ) {
      const rsvCovidInlu = rsvLine.concat(covidLine, influLine);
      seriesFin = rsvCovidInlu.map((a) => a);
    }
    if (
      dataType.indexOf("Covid") > -1 &&
      dataType.indexOf("Influenza") > -1 &&
      dataType.indexOf("Rsv") < 0
    ) {
      const influCovid = covidLine.concat(influLine);
      seriesFin = influCovid.map((a) => a);
    }
    if (
      dataType.indexOf("Influenza") > -1 &&
      dataType.indexOf("Rsv") > -1 &&
      dataType.indexOf("Covid") < 0
    ) {
      const influRsv = rsvLine.concat(influLine);
      seriesFin = influRsv.map((a) => a);
    }
    if (
      dataType.indexOf("Covid") > -1 &&
      dataType.indexOf("Rsv") > -1 &&
      dataType.indexOf("Influenza") < 0
    ) {
      const covRsv = rsvLine.concat(covidLine);
      seriesFin = covRsv.map((a) => a);
    }
    return seriesFin;
  };

  const options = {
    exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          menuItems: ["viewFullscreen"],
        },
      },
    },
    highchartsAccessibility : {
    enabled: "true"
    },
    chart: {
      zoomType: "x"
    },
    title: {
      text: "Labs Ordered vs. Completed",
    },
    legend: {
      align: "right",
      verticalAlign: "top",
      layout: "vertical",
      x: 0,
      y: 100,
    },
    navigator: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    yAxis: [
      {
        tickInterval: 100,
        opposite: false,
        title: {
          text: "Labs Ordered",
        },
      },
      // {
      //   tickInterval: 100,
      //   opposite: true,
      //   title: {
      //     text: "Average",
      //   },
      // }
    ],
    xAxis: {
      type: "datetime",
      minRange: 3600 * 1000 * 24, // one day
    },
    tooltip: {
      shared: true,
      crosshairs: true,
    },

    plotOptions: {
      series: {
        label: {
          connectorAllowed: false,
        },
      },
    },
    series: seriesDecided(dataType),
  };

  return (
    <div>
      <HighchartsReact
        constructorType={"chart"}
        allowChartUpdate={true}
        immutable={true}
        highcharts={Highcharts}
        options={options}
      />
    </div>
  );
}
