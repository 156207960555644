import React, { useState, useRef } from "react";
import HotelIcon from "@material-ui/icons/Hotel";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import "./LiveBedCapacity.css";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { FaHospitalAlt } from "react-icons/fa";
import { List } from "@mui/material";

const useStyles = makeStyles({
  root: {
    height: "30px",
    color: "black",
  },
  text: {
    marginLeft: "-20px",
    color: "#2F5E8C",
    fontStyle: "bold",
  },
  icon: {
    color: "#2F5E8C",
    marginLeft: "-10px",
    fontSize: "25px",
  },
});

function TotalAvailableBeds({ data }) {
  const classes = useStyles();
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);

  const handleClick = (event) => {
    setShow(!show);
    setTarget(event.target);
  };

  const percentageValueEditor = (value) => {
    const value1 = parseInt(value);
    if (value1 == " " || value == 0) {
      return "0";
    } else if (Math.floor(value1) >= 80 && Math.floor(value1) <= 100) {
      const pValueER = Math.round(value1);
      return (
        <>
          <span
            style={{ backgroundColor: "#FF7F7F" }}
            className="badgeTotalNumber"
          >
            {pValueER}%{" "}
          </span>
        </>
      );
    } else if (Math.floor(value1) > 100) {
      const pValueER = Math.round(value1);
      return (
        <>
          {" "}
          <span
            style={{ backgroundColor: "#FF7F7F" }}
            className="badgeTotalNumber"
          >
            {pValueER}%{" "}
          </span>
          <span className="livebadge2 pulsate2">
            <WarningAmberIcon style={{ color: "white", fontSize: "15px" }} />
          </span>{" "}
        </>
      );
    } else if (Math.floor(value1) >= 60 && Math.floor(value1) < 80) {
      const pValueER = Math.round(value1);
      return (
        <>
          <span
            style={{ backgroundColor: "#F2E34C" }}
            className="badgeTotalNumber"
          >
            {pValueER}%{" "}
          </span>
        </>
      );
    } else {
      const pValueER = Math.round(value1);
      return (
        <>
          <span className="badgeTotalNumber">{pValueER}% </span>
        </>
      );
    }
  };

  return (
    <div>
      {/* Total beds card */}
      <h6 className="card-header bg-light">
        <List>
        <ListItem className={classes.root}>
          <ListItemIcon>
            <HotelIcon className={classes.icon} />
          </ListItemIcon>
          <ListItemText disableTypography className={classes.text}>
            TOTAL AVAILABLE BEDS
          </ListItemText>
        </ListItem>{" "}
        </List>
      </h6>{" "}
      <div className="row pt-4 pb-4">
        <div className="col-sm-3" ref={ref}>
          {/* <Example/> */}
          <div className="card">
            <div className="card-body">
              <h2 className="card-title font-weight-bold"> {data.TotalBeds}</h2>
              <span className="card-text">Total Licensed Beds</span>
            </div>
          </div>
        </div>

        <div className="col-sm-3">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title font-weight-bold">
                {" "}
                {data.TotalOccupiedBeds}
              </h2>
              <span className="card-text">Total Occupied Beds</span>
            </div>
          </div>
        </div>

        <div className="col-sm-3">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title font-weight-bold">
                {" "}
                {data.TotalAvaialbleBeds}
              </h2>
              <span className="card-text ">Total Beds Available</span>
            </div>
          </div>
        </div>

        <div className="col-sm-3">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title font-weight-bold">
                {" "}
                {percentageValueEditor(data.PercentageOccupied)}
              </h2>
              <span className="card-text">Percentage Occupied</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TotalAvailableBeds;
