import React, { useMemo, useState, useEffect } from "react";
import BlurOnIcon from "@mui/icons-material/BlurOn";
import Paper from "@mui/material/Paper";
import TotalAdtData from "./TotalAdtData";
import { styled } from "@mui/material/styles";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import AdtReflowLayout from "./adtCharts/AdtReflowLayout";
import AdtOccupancyByHospitals from "./AdtOccupancyByHospitals";
import LinearProgress from "@mui/material/LinearProgress";
import { useOktaAuth } from "@okta/okta-react";
import axios from "axios";
import NotFound from "../../Alerts/NotFound";
import adtDat from '../../../../public/adtTestData.json'

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const useStyles = makeStyles({
  root: {
    height: "30px",
    color: "#31608D",
  },
  text: {
    marginLeft: "-20px",
    fontWeight: "bold",
    fontSize: "30px",
  },
  icon: {
    color: "#31608D",
    marginLeft: "-10px",
  },
});

export default function Adt() {

  const [adtTotalData, setAdtTotalData] = useState([]);
  const [adtTableData, setAdtTableData] = useState([]);
  const [adtChartsData, setAdtChartsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState("");
  const [refreshInterval, setRefreshInterval] = useState(1000);
  const classes = useStyles();
  const { authState, oktaAuth } = useOktaAuth();
  const accessTokenResponse = authState.accessToken;
  const bearerToken = accessTokenResponse?.accessToken;

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      oktaAuth.getUser().then((info) => {
        setUserInfo(info.groups);
      });
    }
  }, [authState, oktaAuth]);

  const getData = () => {
    (async () => {
      setLoading(true);
      const result = await fetch("adtTestData.json", {
        method: "GET",
        headers: {
          // Authorization:'weoifjg0924gjwgj',
          "Content-Type": "application/json",
        },
      });
      const names = await result.json();
      console.log(names)
      setAdtTotalData(names[0].overall);
      setAdtChartsData(names[0].adtChartsData);
      setAdtTableData(names[0].adtTableData);
      setLoading(false);
      // console.log(names.per_hospital_summary)
    })();
  };

  const updateTime = () => {
    var current = new Date();
    return current.toLocaleString();
  };

  const handleGroupAuth = (str) => {
    return userInfo && userInfo.indexOf(str) !== -1;
  };


  useEffect(() => {
    getData();

    const interval = setInterval(() => {

      updateTime();
    }, 100000);

    return () => clearInterval(interval);
  }, []);

  const columns = useMemo(
    () => [
      {
        // first group - TV Show
        Header: "Hospital Name",
        accessor: "hospitalName",
        Cell: ({ cell: { value } }) => {
          return <strong>{value}</strong>;
        },

        // First group columns
      },
      {
        // first group - TV Show
        Header: "IP",

        // First group columns
        columns: [
          {
            Header: "IP Admits",
            accessor: "ipAdmits",
          },
          {
            Header: "IP Discharges",
            accessor: "ipDischarges",
          },
        ],
      },
      {
        // Second group - Details
        Header: "ED",
        // Second group columns
        columns: [
          {
            Header: "ED Admits",
            accessor: "edAdmits",
          },
          {
            Header: "ED Discharges",
            accessor: "edDischarges",
          },
        ],
      },
    ],
    []
  );

  if (handleGroupAuth("Nebraska ADT Dashboard Users")) {
  return (
    <div>
      <div className="mt-5 pt-4">
        <ListItem className={classes.root}>
          <ListItemIcon>
            <BlurOnIcon fontSize="large" className={classes.icon} />
          </ListItemIcon>
          <ListItemText disableTypography>
            <span className={classes.text}>ADT Dashboard</span>
            <div className="d-inline">
              <span className=" ml-3">Last Updated : {updateTime()}</span>
            </div>
          </ListItemText>
        </ListItem>
        <div className="alert alert-light mt-3" role="alert">
          ADT data currently obtained daily from HIE and is as of 06/22/201. HIE
          data only includes data from CyncHealth Participants with executed
          Participant Agreements. Integration of real-time bed ADT data is in
          process by CyncHealth
        </div>
      </div>

      <Paper
        elevation={0}
        style={{
          padding: "10px",
          marginBottom: "20px",
        }}
      >
         <TotalAdtData data={adtTotalData} /> 
     
      </Paper>


        <AdtReflowLayout data={adtChartsData} />
 

      <Paper
        elevation={0}
        style={{
          padding: "10px",
          marginBottom: "10px",
          marginTop:"20px"
        }}
      >
  
          <AdtOccupancyByHospitals columns={columns} data={adtTableData} />

      </Paper>

      {/* <ExampleLayout/> */}

      {/* <Box sx={{ width: '100%' }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

          <Grid item xs={6}>
          <Paper elevation={2} style={{padding:"20px"}}>
              <OrderedVsCompleted/>
          </Paper>
          </Grid>
          <Grid item xs={6}>
          <Paper elevation={2} style={{padding:"20px"}}>
                <TotalPatientsTestedPositive/>
          </Paper>
          </Grid>
          <Grid item xs={6}>
          <Paper elevation={2} style={{padding:"20px"}}>
                <MapComponent/>
          </Paper>
          </Grid>
          <Grid item xs={6}>
          <Paper elevation={2} style={{padding:"20px"}}>
                <TopSixCounties/>
          </Paper>
          </Grid>
          <Grid item xs={6}>
          <Paper elevation={2} style={{padding:"20px"}}>
                <LabVsPositive/>
          </Paper>
          </Grid>
          <Grid item xs={6}>
          <Paper elevation={2} style={{padding:"20px"}}>
                <AgeGenderDist/>
          </Paper>
          </Grid>
          <Grid item xs={6}>
          <Paper elevation={2} style={{padding:"20px"}}>
                <EthnicityDist/>
          </Paper>
          </Grid>
          <Grid item xs={6}>
          <Paper elevation={2} style={{padding:"20px"}}>
                <RaceDist/>
          </Paper>
          </Grid>
        </Grid>
     </Box> */}
      <div className="alert alert-light mt-4" role="alert">
        Disclaimer: Disclosure of identifiable health information is made
        pursuant to Iowa Executive Order No. 20-15 and the federal
        <a
          href="https://www.hhs.gov/sites/default/files/notification-enforcement-discretion-hipaa.pdf?language=es"
          target="_blank"
        >
          HHS Notification of Enforcement Discretion under HIPAA
        </a>
        , as such use is made to individuals who are in a position to prevent
        and lessen a serious and imminent threat to the health and safety of a
        person or the public.
        <br />
      </div>
    </div>
  )} else {
    return (<NotFound/>);
  }
}
