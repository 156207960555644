import React from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import exporting from "highcharts/modules/exporting";
import indicators from "highcharts/indicators/indicators";
import regressions from "highcharts/indicators/regressions";
import trendline from "highcharts/indicators/trendline";
import { useEffect } from "react";
import highchartsAccessibility from "highcharts/modules/accessibility";

import { RsvpOutlined } from "@mui/icons-material";
import { useRef } from "react";
indicators(Highcharts);
regressions(Highcharts);
// import HC_Regression from  "highcharts-regression";
trendline(Highcharts);
exporting(Highcharts);
highchartsAccessibility(Highcharts);

export default function StaffedBedsChart() {

  const options = {
    exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          menuItems: ["viewFullscreen"],
        },
      },
    },
    highchartsAccessibility: {
      enabled: "true",
    },
    chart: {
      zoomType: "x",
    },
    title: {
      text: "Total Staffed Beds",
    },
    legend: {
      align: "right",
      verticalAlign: "top",
      layout: "vertical",
      x: 0,
      y: 100,
    },
    navigator: {
      enabled: false,
    },
    rangeSelector: {
      buttons: [
        {
          type: "week",
          count: 1,
          text: "1w",
        },
        {
          type: "week",
          count: 2,
          text: "2w",
        },
        {
          type: "month",
          count: 1,
          text: "1m",
        },
        {
          type: "all",
          count: 1,
          text: "All",
        },
      ],

      selected: 0,
    },
    credits: {
      enabled: false,
    },
    yAxis: {

      opposite: false,
      title: {
        text: "Total Staffed Beds",
      },
    },
    tooltip: {
      shared: true,
      crosshairs: true,
    },
    xAxis: {
      type: "datetime",
 
    },
    plotOptions: {
      series: {
        label: {
          connectorAllowed: false,
        },
      },
    },
    series: [
        {
          data: [1, 2, 1, 4, 3, 6, 7, 3, 8, 6, 9]
        }
      ]
  };

  return (
    <div>
      <HighchartsReact
        allowChartUpdate={true}
        immutable={true}
        highcharts={Highcharts}
        options={options}
      />
    </div>
  );
}
