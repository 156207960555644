import React, { Component } from "react";
import HighchartsMap from "highcharts/modules/map";
import Highcharts from "highcharts";
import mapData from "../../../../data/Map.jsx";
import exporting from "highcharts/modules/exporting";
import HighchartsReact from "highcharts-react-official";
import { useEffect } from "react";
import highchartsAccessibility from "highcharts/modules/accessibility";

console.log(mapData)
// Load Highcharts Maps as a module
exporting(Highcharts);
HighchartsMap(Highcharts);
highchartsAccessibility(Highcharts);


export default function LabsSampleMap({ data, dataType }) {
  // const [mapTypeData, setMapTypeData] = React.useState([]);

  const calFunc = (obj) => {
    // console.log(obj);
    var holder = {};

    obj.forEach(function (d) {
      if (d == undefined) {
        return null;
      } else if (holder.hasOwnProperty(d.County)) {
        holder[d.County] =
          holder[d.County] + parseInt(d.PatientsTestedPositivePerCounty);
      } else {
        holder[d.County] = parseInt(d.PatientsTestedPositivePerCounty);
      }
    });

    var obj2 = [];

    for (let prop in holder) {
      obj2.push({
        County: prop,
        PatientsTestedPositivePerCounty: holder[prop],
      });
    }

    return obj2;
  };

  // const seriesDecided = (dataType, data) => {
  //   // const rsvMapData = data.map((a) => {
  //   //   return a.NebraskaCasesRsv;
  //   // })

  // };

  // console.log(seriesDecided(dataType, data))
  const selectAndPrepare = (data) => {
    const rsvMapData = data.map((a) => {
      return a.NebraskaCasesRsv;
    });
    const covidMapData = data.map((a) => {
      return a.NebraskaCasesCovid;
    });
    const influenzaMapData = data.map((a) => {
      return a.NebraskaCasesInfluenza;
    });
    // console.log(rsvMapData)
    const finalRsv = calFunc(rsvMapData.flat());
    const finalCovid = calFunc(covidMapData.flat());
    console.log(finalCovid)
    const finalInfluenza = calFunc(influenzaMapData.flat());

    // console.log(finalInfluenza, "finalInfluenza")
    // const msgTotal = finalCovid.reduce(function(prev, cur) {
    //   return prev + cur.PatientsTestedPositivePerCounty;
    // }, 0);

    const allToolTipRsv = finalRsv.map(
      ({
        PatientsTestedPositivePerCounty: PatientsTestedPositivePerCountyRsv,
        ...rest
      }) => ({
        PatientsTestedPositivePerCountyRsv,
        ...rest,
      })
    );
    // console.log(allToolTipRsv)
    const allToolTipInfluenza = finalInfluenza.map(
      ({
        PatientsTestedPositivePerCounty:
          PatientsTestedPositivePerCountyInfluenza,
        ...rest
      }) => ({
        PatientsTestedPositivePerCountyInfluenza,
        ...rest,
      })
    );
    const allToolTipCovid = finalCovid.map(
      ({
        PatientsTestedPositivePerCounty: PatientsTestedPositivePerCountyCovid,
        ...rest
      }) => ({
        PatientsTestedPositivePerCountyCovid,
        ...rest,
      })
    );

    const mergeByCounty = (array1, array2) =>
      array1.map((itm) => ({
        ...array2.find((item) => item.County === itm.County && item),
        ...itm,
      }));
    // console.log(finalCovid, "cov")
    // console.log(finalInfluenza, "influ")
    let finalCovAndInflu = calFunc(finalCovid.concat(finalInfluenza));

    finalCovAndInflu = mergeByCounty(allToolTipCovid, finalCovAndInflu);
    finalCovAndInflu = mergeByCounty(allToolTipRsv, finalCovAndInflu);
    finalCovAndInflu = mergeByCounty(allToolTipInfluenza, finalCovAndInflu);

    let finalInfluAndRsv = calFunc(finalRsv.concat(finalInfluenza));
    finalInfluAndRsv = mergeByCounty(allToolTipCovid, finalInfluAndRsv);
    finalInfluAndRsv = mergeByCounty(allToolTipRsv, finalInfluAndRsv);
    finalInfluAndRsv = mergeByCounty(allToolTipInfluenza, finalInfluAndRsv);

    let finalCovidAndRsv = calFunc(finalRsv.concat(finalCovid));
    finalCovidAndRsv = mergeByCounty(allToolTipCovid, finalCovidAndRsv);
    finalCovidAndRsv = mergeByCounty(allToolTipRsv, finalCovidAndRsv);
    finalCovidAndRsv = mergeByCounty(allToolTipInfluenza, finalCovidAndRsv);

    let finalCovidRsvInflu = calFunc(finalCovidAndRsv.concat(finalInfluenza));
    finalCovidRsvInflu = mergeByCounty(allToolTipCovid, finalCovidRsvInflu);
    finalCovidRsvInflu = mergeByCounty(allToolTipRsv, finalCovidRsvInflu);
    finalCovidRsvInflu = mergeByCounty(allToolTipInfluenza, finalCovidRsvInflu);

    if (
      dataType.indexOf("Rsv") > -1 &&
      dataType.indexOf("Influenza") < 0 &&
      dataType.indexOf("Covid") < 0
    ) {
      return finalRsv;
    } else if (
      dataType.indexOf("Influenza") > -1 &&
      dataType.indexOf("Rsv") < 0 &&
      dataType.indexOf("Covid") < 0
    ) {
      return finalInfluenza;
    } else if (
      dataType.indexOf("Covid") > -1 &&
      dataType.indexOf("Influenza") < 0 &&
      dataType.indexOf("Rsv") < 0
    ) {
      return finalCovid;
    } else if (
      dataType.indexOf("Covid") > -1 &&
      dataType.indexOf("Influenza") > -1 &&
      dataType.indexOf("Rsv") > -1
    ) {
      return finalCovidRsvInflu;
    } else if (
      dataType.indexOf("Covid") > -1 &&
      dataType.indexOf("Influenza") > -1 &&
      dataType.indexOf("Rsv") < 0
    ) {
      return finalCovAndInflu;
    } else if (
      dataType.indexOf("Influenza") > -1 &&
      dataType.indexOf("Rsv") > -1 &&
      dataType.indexOf("Covid") < 0
    ) {
      return finalInfluAndRsv;
    } else if (
      dataType.indexOf("Covid") > -1 &&
      dataType.indexOf("Rsv") > -1 &&
      dataType.indexOf("Influenza") < 0
    ) {
      return finalCovidAndRsv;
    }
  };
  // console.log(selectAndPrepare(data));
  const mapDataType = selectAndPrepare(data);
  // console.log(mapDataType, "map");
  useEffect(() => {}, [data, dataType]);

  const mapData2 =
    mapDataType &&
    mapDataType.map(
      ({ County: name, PatientsTestedPositivePerCounty: value, ...rest }) => ({
        name,
        value,
        ...rest,
      })
    );

  console.log(mapData2)
  console.log(mapData)
  const mapOptions = {
    exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          menuItems: ["viewFullscreen"],
        },
      },
    },
    
    highchartsAccessibility: {
      enabled: "true",
    },
    legend: {
      title: {
        text: "Positive Cases",
      },

      align: "left",
      borderWidth: 1,
      backgroundColor: "transparent",
    },
    //   chart: {
    //     width: 1000
    // },
    title: {
      text: "IOWA Counties",
      style: {
        fontSize: "14px",
      },
    },
    credits: {
      enabled: false,
    },
    colorAxis: {
      min: 0,
      max: 200,

      minColor: "#c5d9fc",
      maxColor: "#414f68",
    },
    mapNavigation: {
      enabled: true,
      enableButtons: false,
    },
    mapView: {
      padding: "0",
    },
    tooltip: {
      formatter: function () {
        if (
          dataType.indexOf("Rsv") > -1 &&
          dataType.indexOf("Influenza") < 0 &&
          dataType.indexOf("Covid") < 0
        ) {
          return `<div>
          County: <b>${this.point.name}</b><br>
          Total Positive Cases: <b>${Math.floor(
            this.point.value
          )} Cases </b><br>
          `;
        } else if (
          dataType.indexOf("Influenza") > -1 &&
          dataType.indexOf("Rsv") < 0 &&
          dataType.indexOf("Covid") < 0
        ) {
          return `<div>
          County: <b>${this.point.name}</b><br>
          Total Positive Cases: <b>${Math.floor(
            this.point.value
          )} Cases </b><br>`;
        } else if (
          dataType.indexOf("Covid") > -1 &&
          dataType.indexOf("Influenza") < 0 &&
          dataType.indexOf("Rsv") < 0
        ) {
          return `<div>
          County: <b>${this.point.name}</b><br>
          Total Positive Cases: <b>${Math.floor(
            this.point.value
          )} Cases </b><br>`;
        } else if (
          dataType.indexOf("Covid") > -1 &&
          dataType.indexOf("Influenza") > -1 &&
          dataType.indexOf("Rsv") > -1
        ) {
          return `<div>
          County: <b>${this.point.name}</b><br>
          Total Positive Cases: <b>${Math.floor(
            this.point.value
          )} Cases </b><br>
          Positive Cases RSV: <b>${
            this.point.PatientsTestedPositivePerCountyRsv
          } Cases </b><br>
          Positive Cases Covid: <b>${
            this.point.PatientsTestedPositivePerCountyCovid
          } Cases </b><br>
          Positive Cases Influenza: <b>${
            this.point.PatientsTestedPositivePerCountyInfluenza
          } Cases </b><br>
          `;
        } else if (
          dataType.indexOf("Covid") > -1 &&
          dataType.indexOf("Influenza") > -1 &&
          dataType.indexOf("Rsv") < 0
        ) {
          return `<div>
          County: <b>${this.point.name}</b><br>
          Total Positive Cases: <b>${Math.floor(
            this.point.value
          )} Cases </b><br>
          Positive Cases Covid: <b>${
            this.point.PatientsTestedPositivePerCountyCovid
          } Cases </b><br>
          Positive Cases Influenza: <b>${
            this.point.PatientsTestedPositivePerCountyInfluenza
          } Cases </b><br>
          `;
        } else if (
          dataType.indexOf("Influenza") > -1 &&
          dataType.indexOf("Rsv") > -1 &&
          dataType.indexOf("Covid") < 0
        ) {
          return `<div>
          County: <b>${this.point.name}</b><br>
          Total Positive Cases: <b>${Math.floor(
            this.point.value
          )} Cases </b><br>
          Positive Cases RSV: <b>${
            this.point.PatientsTestedPositivePerCountyRsv
          } Cases </b><br>
          Positive Cases Influenza: <b>${
            this.point.PatientsTestedPositivePerCountyInfluenza
          } Cases </b><br>
          `;
        } else if (
          dataType.indexOf("Covid") > -1 &&
          dataType.indexOf("Rsv") > -1 &&
          dataType.indexOf("Influenza") < 0
        ) {
          return `<div>
          County: <b>${this.point.name}</b><br>
          Total Positive Cases: <b>${Math.floor(
            this.point.value
          )} Cases </b><br>
          Positive Cases RSV: <b>${
            this.point.PatientsTestedPositivePerCountyRsv
          } Cases </b><br>
          Positive Cases Covid: <b>${
            this.point.PatientsTestedPositivePerCountyCovid
          } Cases </b><br>`;
        }
      },
      backgroundColor: "white",

      borderColor: "white",
    },

    series: [
      {
        data: mapData2 || "",
        mapData: mapData,
        joinBy: "name",

        dataLabels: {
          enabled: true,
          color: "black",
          format: "{point.name}",
        },

        states: {
          hover: {
            color: "#5D7092",
          },
        },
        cursor: "pointer",
        name: "IOWA Positive Cases",
      },
    ],
  };
  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        constructorType={"mapChart"}
        options={mapOptions}
      />
    </div>
  );
}
