import React, { Component } from "react";
import HighchartsMap from "highcharts/modules/map";
import Highcharts from "highcharts";
import mapData from "../../../../data/Map";
import exporting from "highcharts/modules/exporting";
import HighchartsReact from "highcharts-react-official";
import { useEffect } from "react";
import highchartsAccessibility from "highcharts/modules/accessibility";

// Load Highcharts Maps as a module
exporting(Highcharts);
HighchartsMap(Highcharts);
highchartsAccessibility(Highcharts);


export default function MapGeoDistNe() {


  const mapOptions = {
    exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          menuItems: ["viewFullscreen"],
        },
      },
    },
    
    highchartsAccessibility: {
      enabled: "true",
    },
    legend: {
      title: {
        text: "Positive Cases",
      },

      align: "left",
      borderWidth: 1,
      backgroundColor: "transparent",
    },
    //   chart: {
    //     width: 1000
    // },
    title: {
      text: "IOWA Counties",
      style: {
        fontSize: "14px",
      },
    },
    credits: {
      enabled: false,
    },
    colorAxis: {
      min: 0,
      max: 200,

      minColor: "#c5d9fc",
      maxColor: "#414f68",
    },
    mapNavigation: {
      enabled: true,
      enableButtons: false,
    },
    mapView: {
      padding: "2%",
    },
   

    series: [
      {
        data: "",
        mapData: mapData,
        joinBy: "name",

        dataLabels: {
          enabled: true,
          color: "black",
          format: "{point.name}",
        },

        states: {
          hover: {
            color: "#5D7092",
          },
        },
        cursor: "pointer",
        name: "IOWA Positive Cases",
      },
    ],
  };
  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        constructorType={"mapChart"}
        options={mapOptions}
      />
    </div>
  );
}
