import React, { useMemo, useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useTable, useSortBy, useFilters, useGlobalFilter } from "react-table";
import "./LiveBedCapacity.css";
import "./Table.css";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { FaDownload, FaHospitalAlt } from "react-icons/fa";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Papa from "papaparse";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import {
  Divider,
  TextField,
  FormControl,
  IconButton,
  Chip,
  InputLabel,
  OutlinedInput,
  Box,
} from "@material-ui/core";
import { useExportData } from "react-table-plugins";
import "jspdf-autotable";
import JsPDF from "jspdf";
import Button from "@mui/material/Button";
import { useMediaQuery } from "react-responsive";
import { Select, MenuItem } from "@material-ui/core";
import {
  momBabyTabColumnNames,
  hccOptions,
  iowaCounties,
  rmccOptions,
} from "../../services/Constants";
import Checkbox from "@mui/material/Checkbox";
import logo from "../../../../public/CHLogo.png";
import { Filter1Outlined, FilterList } from "@material-ui/icons";

const useStyles = makeStyles({
  root: {
    height: "30px",
    color: "#2F5E8C",
  },
  text: {
    marginLeft: "-20px",
    fontStyle: "bold",
  },
  icon: {
    color: "#2F5E8C",
    marginLeft: "-10px",
  },
});
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  sx: {
    "&& .Mui-selected": {
      backgroundColor: "pink",
    },
  },
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "auto",
    },
  },
};
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;
  return (
    <input
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}
function multiSelectFilter(rows, columnIds, filterValue) {
  return filterValue.length === 0
    ? rows
    : rows.filter((row) =>
        filterValue.includes(String(row.original[columnIds]))
      );
}

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "white",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
    maxWidth: 300,
    margin: "auto",
    transition: "0.3s",
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
    "&:hover": {
      boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)",
    },
    lineHeight: 2,
  },
}));

export default function MomAndBabyBedsTable({ data }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [columnsToExport, setColumnsToExport] = useState([]);
  const [openExportPopup, setOpenExportPopup] = useState(false);
  const [openFilterPopup, setOpenFilterPopup] = useState(false);
  const [filterInput, setFilterInput] = useState("");
  const [typeFilterInput, setTypeFilterInput] = useState("");
  const [cityFilterInput, setCityFilterInput] = useState("");
  const [countyFilterInput, setCountyFilterInput] = useState([]);
  const [zipFilterInput, setZipFilterInput] = useState("");
  const [ahaFilterInput, setAhaFilterInput] = useState("");
  const [nmdsFilterInput, setNmdsFilterInput] = useState("");
  const [rmccFilterInput, setRmccFilterInput] = useState([]);
  const [hccFilterInput, setHccFilterInput] = useState([]);
  const isAllColumnsToExportSelected =
    momBabyTabColumnNames.length > 0 &&
    columnsToExport?.length === momBabyTabColumnNames.length;

  const isAllCountiesSelected =
    iowaCounties.length > 0 &&
    countyFilterInput?.length === iowaCounties.length;

  const isAllRmccSelected =
    rmccOptions.length > 0 && rmccFilterInput?.length === rmccOptions.length;

  const isAllHccSelected =
    hccOptions.length > 0 && hccFilterInput?.length === hccOptions.length;
  const isMobileDevice = useMediaQuery({
    query: "(max-device-width: 480px)",
  });
  const isTabDevice = useMediaQuery({
    query: "(max-device-width: 1150px) ",
  });
  const classes = useStyles();

  const handleFilterChange = (e) => {
    const value = e.target.value || undefined;
    setFilter("HOSPITAL_NAME", value);
    setFilterInput(value);
  };

  const handleTypeFilterChange = (e) => {
    const value = e.target.value || undefined;
    setFilter("HOSPITAL_TYPE", value);
    setTypeFilterInput(value);
  };
  const handleSelectedColumns = (event) => {
    const value = event.target.value || undefined;
    if (value[value.length - 1] === "all") {
      setColumnsToExport(
        columnsToExport?.length === momBabyTabColumnNames.length
          ? []
          : momBabyTabColumnNames
      );
      return;
    }
    setColumnsToExport(value);
  };
  const handleNmdsFilterChange = (e) => {
    const value = e.target.value || undefined;
    setFilter("NMDS", value);
    setNmdsFilterInput(value);
  };
  const handleCityFilterChange = (e) => {
    const value = e.target.value || undefined;
    setFilter("HQ_CITY", value);
    setCityFilterInput(value);
  };

  const handleCountyFilterChange = (e) => {
    const value = e.target.value || undefined;
    if (value[value.length - 1] === "all") {
      setCountyFilterInput(
        countyFilterInput?.length === iowaCounties.length ? [] : iowaCounties
      );
      setFilter(
        "COUNTY_NAME",
        countyFilterInput?.length === iowaCounties.length ? [] : iowaCounties
      );
      return;
    }
    setFilter("COUNTY_NAME", value);
    setCountyFilterInput(value);
  };

  const handleZipFilterChange = (e) => {
    const value = e.target.value || undefined;
    setFilter("HQ_ZIP_CODE", value);
    setZipFilterInput(value);
  };

  const handleAhaFilterChange = (e) => {
    const value = e.target.value || undefined;
    setFilter("Facility_AHA_ID", value);
    setAhaFilterInput(value);
  };

  const handleHccFilterChange = (e) => {
    const value = e.target.value || undefined;
    if (value[value.length - 1] === "all") {
      setHccFilterInput(
        hccFilterInput?.length === hccOptions.length ? [] : hccOptions
      );
      setFilter(
        "HCC",
        hccFilterInput?.length === hccOptions.length ? [] : hccOptions
      );
      return;
    }
    setFilter("HCC", value);
    setHccFilterInput(value);
  };

  const handleRmccFilterChange = (e) => {
    const value = e.target.value || undefined;
    if (value[value.length - 1] === "all") {
      setRmccFilterInput(
        rmccFilterInput?.length === rmccOptions.length ? [] : rmccOptions
      );
      setFilter(
        "RMCC",
        rmccFilterInput?.length === rmccOptions.length ? [] : rmccOptions
      );
      return;
    }
    setFilter("RMCC", value);
    setRmccFilterInput(value);
  };

  const handleExportPopupClose = () => {
    setOpenExportPopup(false);
  };
  const handleFiltersPopupClose = () => {
    setOpenFilterPopup(false);
  };
  const keepCollectionProperties = (obj, keep) => {
    for (let i = 0; i < obj.length; i++) {
      for (let key in obj[i]) {
        if (keep.indexOf(key) === -1) {
          delete obj[i][key];
        }
      }
    }
    return obj;
  };

  function getExportFileBlob({ columns, data, fileType, fileName }) {
    const selectedColumns = columns.filter((col) => {
      return columnsToExport.some((e) => col.Header.includes(e));
    });

    if (fileType === "csv") {
      // CSV example
      const headerNames = columns.map((col) => col.exportValue);
      const csvString = Papa.unparse({ fields: headerNames, data });
      return new Blob([csvString], { type: "text/csv" });
    }
    //PDF example

    if (fileType === "pdf") {
      const headerNames = selectedColumns.map((column) => column.exportValue);
      const objs = data.map((x) => ({
        "Hospital Name": x[0],
        "Hospital Type": x[1],
        NMDS: x[2],
        "Hospital City": x[3],
        "Hospital County": x[4],
        "Hospital Zip": x[5],
        "AHA Number": x[6],
        RMCC: x[7],
        HCC: x[8],
        "Total L&D Licensed Beds": x[9],
        "Total L&D Occupied Beds": x[10],
        "Total L&D Beds Available": x[11],
        "L&D Percentage Occupied": x[12],
        "Total Licensed Beds": x[13],
        "Total Occupied Beds": x[14],
        "Total Beds Available": x[15],
        "Percentage Occupied": x[16],
      }));

      const final = keepCollectionProperties(objs, headerNames);
      const finalPdfData = final.map((e) => Object.values(e));

      const doc = new JsPDF("landscape");
      doc.addImage(logo, "PNG", 16, 4, 25, 7);
      doc.setTextColor(110);
      doc.setFontSize(10);
      doc.text(230, 10, "IDPH Bed Occupancy Report");
      doc.setFontSize(8);

      doc.text(
        10,
        18,
        "Disclaimer : This Dashboard is provided as a service to Authorized Users pursuant to applicable Data Sharing and Participation Agreements and in compliance with all applicable laws. Users are restricted from the following "
      );
      doc.text(
        10,
        21,
        "activities pursuant to the Agreements: make this Service available to any other person, entity or business; copy, reverse-engineer, decompile, or disassemble the System or data contained herein; and modify or combine the"
      );
      doc.text(
        10,
        24,
        "System with any other software or services not provided or approved by CyncHealth."
      );

      doc.text(
        10,
        29,
        "Data shown calculates beds occupied by total beds minus available beds. This near-real-time occupancy data comes from the state HIE (CyncHealth) and so only contains those facilities who are actively sharing data with"
      );
      doc.text(
        10,
        32,
        "CyncHealth. Bed types are obtained from the interface message and can only reflect the data as shared by the facility."
      );
      const date = new Date();
      doc.text("Reported Time: " + date, 185, 190);
      doc.autoTable({
        head: [headerNames],
        body: finalPdfData,
        startY: 35,

        styles: {
          theme: "grid",
          minCellHeight: 15,
          top: 30,

          halign: "left",
          valign: "top",
          fontSize: 6,
          overflow: "linebreak",
        },
      });

      doc.save(`${fileName}.pdf`);

      return false;
    }

    // Other formats goes here
    return false;
  }
  const columns = useMemo(
    () => [
      {
        // first group - TV Show
        Header: "Hospital Name",
        accessor: "HOSPITAL_NAME",
        disableFilters: true,
        Footer: "Total",
        Cell: (row) => {
          return (
            <div>
              <HtmlTooltip
                title={
                  <React.Fragment>
                    <Typography color="inherit">
                      Hospital Information
                    </Typography>
                    <Divider />
                    <div>Facility Name : {row.row.values["HOSPITAL_NAME"]}</div>
                    <div>
                      Address : {row.row.values["HQ_ADDRESS1"]},
                      {row.row.values["HQ_CITY"]},{row.row.values["HQ_CITY"]},
                      {row.row.values["HQ_STATE"]},
                      {row.row.values["HQ_ZIP_CODE"]}
                    </div>
                    <div>County : {row.row.values["COUNTY_NAME"]}</div>

                    <div>RMCC Region : {row.row.values["RMCC"]} </div>

                    <div> HCC Region : {row.row.values["HCC"]}</div>

                    <div>AHA Id : {row.row.values["Facility_AHA_ID"]} </div>
                    <div>
                      {" "}
                      Provider Transfer Contact:{" "}
                      {row.row.values["PHONE_TRANSFER_CONTACT"]}
                    </div>
                    <div> Phone Main : {row.row.values["PHONE_MAIN"]}</div>
                    <div>
                      {" "}
                      Licensed Beds : {row.row.values["Total_Licensed_Beds"]}
                    </div>
                  </React.Fragment>
                }
              >
                <strong> {row.row.values["HOSPITAL_NAME"]}</strong>
              </HtmlTooltip>
            </div>
          );
        },
      },
      {
        Header: "Hospital Type",
        accessor: "HOSPITAL_TYPE",
        disableFilters: true,
        Footer: "",
      },
      {
        Header: "NMDS",
        accessor: "NMDS",
        disableFilters: true,
        Footer: "",
      },
      {
        Header: "Hospital City",
        accessor: "HQ_CITY",
        disableFilters: true,
        Footer: "",
      },
      {
        Header: "Hospital County",
        accessor: "COUNTY_NAME",
        disableFilters: true,
        filter: multiSelectFilter,
        show: false,
        Footer: "",
      },
      {
        Header: "Hospital Zip",
        accessor: "HQ_ZIP_CODE",
        disableFilters: true,
        Footer: "",
      },
      {
        Header: "AHA Number",
        accessor: "Facility_AHA_ID",
        disableFilters: true,
        Footer: "",
      },
      {
        Header: "RMCC",
        accessor: "RMCC",
        disableFilters: true,
        filter: multiSelectFilter,
        show: false,
        Footer: "",
      },
      {
        Header: "HCC",
        accessor: "HCC",
        disableFilters: true,
        filter: multiSelectFilter,
        show: false,
        Footer: "",
      },
      {
        // first group - TV Show
        Header: "Labor And Delivery",
        tipText: `Inpatient beds used for Mom and Baby Labor and Delivery`,
        Footer: "",
        columns: [
          {
            Header: "Total L&D Licensed Beds",
            accessor: "Total_Licensed_Labor_Delivery_Beds",
            disableFilters: true,
            Footer: (info) => {
              // Only calculate total visits if rows change
              const total = React.useMemo(
                () =>
                  info.rows.reduce(
                    (sum, row) =>
                      row.values.Total_Licensed_Labor_Delivery_Beds + sum,
                    0
                  ),
                [info.rows]
              );

              return <>{total}</>;
            },
          },
          {
            Header: "Total L&D Occupied Beds",
            accessor: "Labor_Delivery_Occupied_Beds",
            id: "occupiedBeds",
            Footer: (info) => {
              // Only calculate total visits if rows change
              const total = React.useMemo(
                () =>
                  info.rows.reduce(
                    (sum, row) =>
                      row.values.Total_Licensed_Labor_Delivery_Beds -
                      row.values.Labor_Delivery_Available_Beds +
                      sum,
                    0
                  ),
                [info.rows]
              );

              return <>{total}</>;
            },
            disableFilters: true,
          },
          {
            Header: "Total L&D Beds Available",
            accessor: "Labor_Delivery_Available_Beds",
            disableFilters: true,
            Footer: (info) => {
              // Only calculate total visits if rows change
              const total = React.useMemo(
                () =>
                  info.rows.reduce(
                    (sum, row) =>
                      row.values.Labor_Delivery_Available_Beds + sum,
                    0
                  ),
                [info.rows]
              );

              return <>{total}</>;
            },
          },
          {
            Header: "L&D Percentage Occupied",
            accessor: "Percentage_Labor_Delivery_Occupied",
            disableFilters: true,
            Cell: ({ cell: { value } }) => {
              const value1 = parseInt(value);
              if (value1 == " " || value == 0) {
                return "0";
              } else if (
                Math.floor(value1) >= 80 &&
                Math.floor(value1) <= 100
              ) {
                const pValueER = Math.round(value1);
                return (
                  <>
                    <span
                      style={{ backgroundColor: "#FF7F7F" }}
                      className="badge"
                    >
                      {pValueER}%{" "}
                    </span>
                  </>
                );
              } else if (Math.floor(value1) > 100) {
                const pValueER = Math.round(value1);
                return (
                  <>
                    {" "}
                    <span
                      style={{ backgroundColor: "#FF7F7F" }}
                      className="badge"
                    >
                      {pValueER}%{" "}
                    </span>
                    <span className="livebadge2 pulsate2">
                      <WarningAmberIcon
                        style={{ color: "white", fontSize: "15px" }}
                      />
                    </span>{" "}
                  </>
                );
              } else if (Math.floor(value1) >= 60 && Math.floor(value1) < 80) {
                const pValueER = Math.round(value1);
                return (
                  <>
                    <span
                      style={{ backgroundColor: "#F2E34C" }}
                      className="badge"
                    >
                      {pValueER}%{" "}
                    </span>
                  </>
                );
              } else {
                const pValueER = Math.round(value1);
                return (
                  <>
                    <span className="badge">{pValueER}% </span>
                  </>
                );
              }
            },
            Footer: "",
          },
        ],
      },
      {
        // Second group - Details
        Header: "Newborn",
        tipText: `Beds used for the care of Newborn babies`,
        Footer: "",
        // Second group columns
        columns: [
          {
            Header: "Total Licensed Beds",
            accessor: "Total_Licensed_Newborn_Beds",
            disableFilters: true,
            Footer: (info) => {
              // Only calculate total visits if rows change
              const total = React.useMemo(
                () =>
                  info.rows.reduce(
                    (sum, row) => row.values.Total_Licensed_Newborn_Beds + sum,
                    0
                  ),
                [info.rows]
              );

              return <>{total}</>;
            },
          },
          {
            Header: "Total Occupied Beds",
            accessor: "Newborn_Occupied_Beds",
            disableFilters: true,
            Footer: (info) => {
              // Only calculate total visits if rows change
              const total = React.useMemo(
                () =>
                  info.rows.reduce(
                    (sum, row) => row.values.Newborn_Occupied_Beds + sum,
                    0
                  ),
                [info.rows]
              );

              return <>{total}</>;
            },
          },
          {
            Header: "Total Beds Available",
            accessor: "Newborn_Available_Beds",
            disableFilters: true,
            Footer: (info) => {
              // Only calculate total visits if rows change
              const total = React.useMemo(
                () =>
                  info.rows.reduce(
                    (sum, row) => row.values.Newborn_Available_Beds + sum,
                    0
                  ),
                [info.rows]
              );

              return <>{total}</>;
            },
          },
          {
            Header: "Percentage Occupied",
            accessor: "Percentage_Newborn_Occupied",
            disableFilters: true,
            Cell: ({ cell: { value } }) => {
              const value1 = parseInt(value);
              if (value1 == " " || value == 0) {
                return "0";
              } else if (
                Math.floor(value1) >= 80 &&
                Math.floor(value1) <= 100
              ) {
                const pValueER = Math.round(value1);
                return (
                  <>
                    <span
                      style={{ backgroundColor: "#FF7F7F" }}
                      className="badge"
                    >
                      {pValueER}%{" "}
                    </span>
                  </>
                );
              } else if (Math.floor(value1) > 100) {
                const pValueER = Math.round(value1);
                return (
                  <>
                    {" "}
                    <span
                      style={{ backgroundColor: "#FF7F7F" }}
                      className="badge"
                    >
                      {pValueER}%{" "}
                    </span>
                    <span className="livebadge2 pulsate2">
                      <WarningAmberIcon
                        style={{ color: "white", fontSize: "15px" }}
                      />
                    </span>{" "}
                  </>
                );
              } else if (Math.floor(value1) >= 60 && Math.floor(value1) < 80) {
                const pValueER = Math.round(value1);
                return (
                  <>
                    <span
                      style={{ backgroundColor: "#F2E34C" }}
                      className="badge"
                    >
                      {pValueER}%{" "}
                    </span>
                  </>
                );
              } else {
                const pValueER = Math.round(value1);
                return (
                  <>
                    <span className="badge">{pValueER}% </span>
                  </>
                );
              }
            },
            Footer: "",
          },
        ],
      },
    ],
    []
  );
  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    setFilter,
    exportData,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        sortBy: [{ id: "occupiedBeds", desc: true }],
      },
      getExportFileBlob,
    },

    useFilters,
    useGlobalFilter,
    useSortBy,
    useExportData
  );

  return (
    <div>
      <div className={isMobileDevice ? "" : isTabDevice ? "" : "occupancyBox"}>
        <h6 className="card-header bg-light">
          <ListItem className={classes.root}>
            <ListItemIcon>
              <FaHospitalAlt size={25} className={classes.icon} />
            </ListItemIcon>
            <ListItemText disableTypography className={classes.text}>
              OCCUPANCY BY HOSPITAL
            </ListItemText>
            <div>
              {isMobileDevice ? (
                <IconButton
                  style={{ color: "#2F5E8C" }}
                  id="demo-positioned-button"
                  aria-controls={open ? "demo-positioned-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={setOpenExportPopup}
                >
                  <FaDownload />
                </IconButton>
              ) : (
                <Button
                  variant="outlined"
                  endIcon={<FaDownload />}
                  id="demo-positioned-button"
                  aria-controls={open ? "demo-positioned-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={setOpenExportPopup}
                >
                  Export
                </Button>
              )}

              <Dialog open={openExportPopup} onClose={handleExportPopupClose}>
                <DialogTitle>PDF Exporting Options</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Please select all columns you want to export.
                  </DialogContentText>
                  <FormControl style={{ width: "90%", margin: 3 }}>
                    <InputLabel id="demo-multiple-chip-label">
                      Columns
                    </InputLabel>

                    <Select
                      labelId="demo-multiple-chip-label"
                      id="demo-multiple-chip"
                      multiple={true}
                      value={columnsToExport || []}
                      MenuProps={MenuProps}
                      onChange={(event) => handleSelectedColumns(event)}
                      renderValue={(selected) => (
                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 3 }}>
                          {selected.map((value) => (
                            <Chip key={value} label={value} />
                          ))}
                        </Box>
                      )}
                    >
                      <MenuItem value="all">
                        <Checkbox checked={isAllColumnsToExportSelected} />
                        <ListItemText primary="Select All" />
                      </MenuItem>
                      {momBabyTabColumnNames.map((name) => {
                        return (
                          <MenuItem key={name} value={name}>
                            {" "}
                            <Checkbox
                              checked={columnsToExport.indexOf(name) > -1}
                            />
                            <ListItemText primary={name} />{" "}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
                      exportData("pdf", true);
                    }}
                  >
                    Export All
                  </Button>
                  <Button
                    onClick={() => {
                      exportData("pdf", false);
                    }}
                  >
                    Export Current View
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </ListItem>{" "}
        </h6>
        <div
          style={{
            display: "flex",
            flexDirection: isMobileDevice ? "column" : "row",
            margin: "10px",
          }}
        >
          <span
            style={{
              fontSize: "14px",
              fontWeight: "bolder",
              marginRight: "10px",
              paddingTop: "15px",
            }}
          >
            Search By :{" "}
          </span>
          <Box>
            <TextField
              style={{ width: 160, margin: 2.5 }}
              size="small"
              id="filled-search"
              type="search"
              label="Hospital name"
              variant="filled"
              value={filterInput}
              onChange={handleFilterChange}
            />
            <TextField
              style={{ width: 120, margin: 2.5 }}
              size="small"
              id="filled-search"
              type="search"
              label="City"
              variant="filled"
              value={cityFilterInput}
              onChange={handleCityFilterChange}
            />
            <TextField
              style={{ width: 120, margin: 2.5 }}
              size="small"
              id="filled-search"
              type="search"
              label="Zip"
              variant="filled"
              value={zipFilterInput}
              onChange={handleZipFilterChange}
            />
            <TextField
              style={{ width: 150, margin: 2.5 }}
              size="small"
              id="filled-search"
              type="search"
              label="AHA Number"
              variant="filled"
              value={ahaFilterInput}
              onChange={handleAhaFilterChange}
            />
            {isMobileDevice ? (
              <IconButton
                style={{ color: "#2F5E8C" }}
                id="demo-positioned-button"
                tooltip="More FIlters"
                aria-controls={open ? "demo-positioned-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={setOpenFilterPopup}
              >
                <FilterList />
              </IconButton>
            ) : (
              <Button
                style={{ margin: "7px" }}
                variant="outlined"
                endIcon={<FilterList />}
                id="demo-positioned-button"
                aria-controls={open ? "demo-positioned-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={setOpenFilterPopup}
              >
                More Filters
              </Button>
            )}
            <Dialog open={openFilterPopup} onClose={handleFiltersPopupClose}>
              <DialogTitle>
                {" "}
                <FilterList /> Filters :{" "}
              </DialogTitle>
              <DialogContent>
                <FormControl
                  className="filter-group"
                  variant="filled"
                  size="small"
                  style={{ width: 150, margin: 2.5 }}
                >
                  <InputLabel id="demo-multiple-name-label">County</InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    displayEmpty
                    variant="filled"
                    multiple={true}
                    value={countyFilterInput || []}
                    MenuProps={MenuProps}
                    onChange={(event) => handleCountyFilterChange(event)}
                    label="County"
                    renderValue={(selected) => selected.join(", ")}
                  >
                    <MenuItem value="all">
                      <Checkbox checked={isAllCountiesSelected} />
                      <ListItemText primary="Select All" />
                    </MenuItem>
                    {iowaCounties.map((c) => {
                      return (
                        <MenuItem key={c} value={c}>
                          {" "}
                          <Checkbox
                            checked={countyFilterInput.indexOf(c) > -1}
                          />
                          <ListItemText primary={c} />{" "}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl
                  className="filter-group"
                  variant="filled"
                  size="small"
                  style={{ width: 160, margin: 2.5 }}
                >
                  <InputLabel id="demo-multiple-name-label">
                    Hospital Type
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    value={typeFilterInput}
                    onChange={handleTypeFilterChange}
                    label="Hospital Type"
                  >
                    <MenuItem value="">Hospital Type (All types)</MenuItem>
                    <MenuItem value="Critical Access Hospital">
                      Critical Access Hospital
                    </MenuItem>
                    <MenuItem value="Short Term Acute Care Hospital">
                      Short Term Acute Care Hospital
                    </MenuItem>
                  </Select>
                </FormControl>
                <FormControl
                  className="filter-group"
                  variant="filled"
                  size="small"
                  style={{ width: 160, margin: 2.5 }}
                >
                  <InputLabel id="demo-multiple-name-label">NDMS</InputLabel>
                  <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    value={nmdsFilterInput}
                    onChange={handleNmdsFilterChange}
                    label="NDMS"
                  >
                    <MenuItem value="">All Hospitals</MenuItem>
                    <MenuItem value="1">NDMS Hospitals</MenuItem>
                    <MenuItem value="0">Non-NDMS Hospitals</MenuItem>
                  </Select>
                </FormControl>
                <FormControl
                  className="filter-group"
                  size="small"
                  variant="filled"
                  style={{ minWidth: 100, width: "auto", margin: 2.5 }}
                >
                  <InputLabel id="demo-multiple-name-label">HCC</InputLabel>
                  <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    displayEmpty
                    multiple={true}
                    value={hccFilterInput || []}
                    MenuProps={MenuProps}
                    onChange={(event) => handleHccFilterChange(event)}
                    label="HCC"
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 3 }}>
                        {selected.map((value) => (
                          <Chip key={value} label={value} />
                        ))}
                      </Box>
                    )}
                  >
                    <MenuItem value="all">
                      <Checkbox checked={isAllHccSelected} />
                      <ListItemText primary="Select All" />
                    </MenuItem>
                    {hccOptions.map((c) => {
                      return (
                        <MenuItem key={c} value={c}>
                          {" "}
                          <Checkbox checked={hccFilterInput.indexOf(c) > -1} />
                          <ListItemText primary={c} />{" "}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl
                  className="filter-group"
                  size="small"
                  variant="filled"
                  style={{ minWidth: 100, width: "auto", margin: 2.5 }}
                >
                  <InputLabel id="demo-multiple-name-label">RMCC</InputLabel>
                  <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    displayEmpty
                    variant="filled"
                    multiple={true}
                    value={rmccFilterInput || []}
                    MenuProps={MenuProps}
                    onChange={(event) => handleRmccFilterChange(event)}
                    label="RMCC"
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 3 }}>
                        {selected.map((value) => (
                          <Chip key={value} label={value} />
                        ))}
                      </Box>
                    )}
                  >
                    <MenuItem value="all">
                      <Checkbox checked={isAllRmccSelected} />
                      <ListItemText primary="Select All" />
                    </MenuItem>
                    {rmccOptions.map((c) => {
                      return (
                        <MenuItem key={c} value={c}>
                          {" "}
                          <Checkbox checked={rmccFilterInput.indexOf(c) > -1} />
                          <ListItemText primary={c} />{" "}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    setOpenFilterPopup(false);
                  }}
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        </div>
      </div>
      <div className={isTabDevice ? "tableWrap" : ""}>
        <table className="mombaby" {...getTableProps()}>
          <thead id={isTabDevice ? "" : isMobileDevice ? "" : "sticky-thc"}>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    id="new-tooltip"
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={
                      column.isSorted
                        ? column.isSortedDesc
                          ? "sort-desc"
                          : "sort-asc"
                        : ""
                    }
                  >
                    {column.render("Header")}
                    {headerGroup.headers[index].tipText && (
                      <span>{headerGroup.headers[index].tipText}</span>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
            {footerGroups.map((group) => (
              <tr id="footer-row" {...group.getFooterGroupProps()}>
                {group.headers.map((column) => (
                  <td {...column.getFooterProps()}>
                    {column.render("Footer")}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
