import React, { useState, useEffect, useRef } from "react";
import ScienceIcon from "@mui/icons-material/Science";
import Paper from "@mui/material/Paper";
// import TotalLabsData from "./TotalLabsData";
// import UniquePatients from "./UniquePatients";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
// import ExampleLayout from "./labsCharts/LabsReflowLayout";
import axios from "axios";
import InputAdornment from "@mui/material/InputAdornment";
import { useOktaAuth } from "@okta/okta-react";
import LinearProgress from "@mui/material/LinearProgress";
import NotFound from "../../Alerts/NotFound";
import { Refresh, SyncProblemOutlined } from "@material-ui/icons";
import LoadingButton from "@mui/lab/LoadingButton";
import Typography from "@mui/material/Typography";
import { useMediaQuery } from "react-responsive";
import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import { Box, Button } from "@mui/material";
import TotalLabsSummary from "./TotalLabsSummary";
import TotalUniquePatsSummary from "./TotalUniquePatsSummary";
import MyFirstGrid from "./labsSummaryCharts/ChartsLayout";
import Multiselect from "react-widgets/esm/Multiselect";
import "react-widgets/styles.css";
import PropTypes, { object } from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { DateRange, InfoRounded } from "@mui/icons-material";
import MySecondGrid from "./labsSummaryCharts/ChartsLayoutDemographics";
import "../labsSummaryCopy/labsSummaryCharts/styles.css";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRangePicker } from "react-date-range";
import format from "date-fns/format";
import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
} from "date-fns";

const defineds = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  startOfTheData: startOfDay(new Date("10/20/2022")),
};

const useStyles = makeStyles({
  root: {
    height: "30px",
    color: "#2F5E8C",
    marginBottom: "20px",
  },
  text: {
    marginLeft: "-20px",
    fontWeight: "bold",
    fontSize: "20px",
  },
  icon: {
    color: "#2F5E8C",
    marginLeft: "-10px",
  },
  tabs: {
    "& .MuiTabs-indicator": {
      display: "none",
    },
    "& .Mui-selected": {
      backgroundColor: "#2F5E8C",
      color: "white",
      boxShadow: "2px 2px 10px 0px black",
    },
    "& .Mui-disabled": {
      color: "rgb(212, 212, 212)",
    },
  },
});
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tabpanel-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tabpanel-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
export default function LabsSummaryCopy() {
  const [value, setValue] = React.useState(
    parseInt(sessionStorage.getItem("tabValue")) || 0
  );
  const [openDisclaimer, setOpenDisclaimer] = React.useState(false);
  const [range, setRange] = useState([
    {
      startDate: new Date("10/20/2022"),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [lastOneWeekData, setLastOneWeekData] = React.useState([]);
  const [dataTypeFilter, setDataTypeFilter] = useState(["Rsv"]);
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [labsData, setLabsData] = useState([]);
  const { authState, oktaAuth } = useOktaAuth();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [userInfo, setUserInfo] = useState("");
  const [open, setOpen] = useState(false);
  // get the target element to toggle
  const refOne = useRef(null);
  const classes = useStyles();
  const accessTokenResponse = authState.accessToken;
  const bearerToken = accessTokenResponse?.accessToken;
  const isMobileDevice = useMediaQuery({
    query: "(max-device-width: 480px)",
  });

  const labsFilterOptions = ["Rsv", "Covid", "Influenza"];
  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      oktaAuth.getUser().then((info) => {
        setUserInfo(info.groups);
      });
    }
  }, [authState, oktaAuth]);

  const getDataFromApi = async (dataType) => {
    setShowError(false);
    setLoading(true);
 
      setLoading(true);
      const result = await fetch(`labs${dataType}.json`, {
        method: "GET",
        headers: {
          // Authorization:'weoifjg0924gjwgj',
          "Content-Type": "application/json",
        },
      });
      setLoading(false);
      const names = await result.json();
      return names;
    
      // console.log(names.per_hospital_summary)
 
        
  

  };

  const getLabsData = async () => {
    try {
      const rsvData = await getDataFromApi("Rsv");
      const influenzaData = await getDataFromApi("Influenza");
      const covidData = await getDataFromApi("Covid");
      const rsvFinalData = rsvData[0];

      const covidFinalData = covidData[0];
      const influenzaFinalData = influenzaData[0];
      const labsData = {};
      const rsvMapData = groupBy(rsvFinalData.nebraskaCases, "date");
      const inluenzaMapData = groupBy(influenzaFinalData.nebraskaCases, "date");
      const covidMapData = groupBy(covidFinalData.nebraskaCases, "date");

      const rsvAgeAndGenderData = groupBy(
        rsvFinalData.ageGenderDist,
        "startDate"
      );
      const inluenzaAgeAndGenderData = groupBy(
        influenzaFinalData.ageGenderDist,
        "startDate"
      );
      const covidAgeAndGenderData = groupBy(
        covidFinalData.ageGenderDist,
        "startDate"
      );

      // console.log(rsvAgeAndGenderData)
      for (const property in rsvMapData) {
        labsData[property] = {
          ...labsData[property],
          Date: property,
          NebraskaCasesRsv: Object.values(rsvMapData[property]),
        };
      }
      for (const property in inluenzaMapData) {
        labsData[property] = {
          ...labsData[property],
          Date: property,
          NebraskaCasesInfluenza: Object.values(inluenzaMapData[property]),
        };
      }
      for (const property in covidMapData) {
        labsData[property] = {
          ...labsData[property],
          Date: property,
          NebraskaCasesCovid: Object.values(covidMapData[property]),
        };
      }

      for (const property in rsvAgeAndGenderData) {
        labsData[property] = {
          ...labsData[property],
          Date: property,
          AgeAndGenderRsv: Object.values(rsvAgeAndGenderData[property]),
        };
      }
      for (const property in inluenzaAgeAndGenderData) {
        labsData[property] = {
          ...labsData[property],
          Date: property,
          AgeAndGenderInfluenza: Object.values(
            inluenzaAgeAndGenderData[property]
          ),
        };
      }
      for (const property in covidAgeAndGenderData) {
        labsData[property] = {
          ...labsData[property],
          Date: property,
          AgeAndGenderCovid: Object.values(covidAgeAndGenderData[property]),
        };
      }

      rsvFinalData.labsTotals.forEach((obj) => {
        const date = obj.date;
        if (!labsData[date]) labsData[date] = {};

        labsData[date] = {
          ...labsData[date],
          Date: date,
          TotalLabsOrderedRsv: obj.TotalLabsOrdered,
          TotalLabsCompletedRsv: obj.TotalLabsCompleted,
          TotalTestedPositiveRsv: obj.TotalTestedPositive,
          TotalPositiveRateRsv: obj.PositiveRate,
        };
      });
      rsvFinalData.raceDist.forEach((obj) => {
        const date = obj.date;
        if (!labsData[date]) labsData[date] = {};

        labsData[date] = {
          ...labsData[date],
          Date: date,
          AmericanIndianAlaskaNativeRsv: obj.AmericanIndianAlaskaNative,
          AsianRsv: obj.Asian,
          BlackAfricanAmericanRsv: obj.BlackAfricanAmerican,
          HispanicLatinoRsv: obj.HispanicLatino,
          NativeHawaiianOrOtherPacificIslanderRsv:
            obj.NativeHawaiianOrOtherPacificIslander,
          NonHispanicLatinoRsv: obj.NonHispanicLatino,
          UnknownsRsv: obj.Unknown,
          otherRsv: obj.other,
          whiteRsv: obj.white,
          NotReportedRsv: obj.NotReported,
          IncompleteRsv: obj.Incomplete,
        };
      });

      rsvFinalData.uniquePatientsTotals.forEach((obj) => {
        const date = obj.date;
        if (!labsData[date]) labsData[date] = {};

        labsData[date] = {
          ...labsData[date],
          Date: date,
          UniquePatientsLabsOrderedRsv: obj.TotalLabsOrdered,
          UniquePatientsLabsCompletedRsv: obj.TotalLabsCompleted,
          UniquePatientsTestedPositiveRsv: obj.TotalTestedPositive,
          UniquePatientsPositiveRateRsv: obj.PositiveRate,
        };
      });

      covidFinalData.labsTotals.forEach((obj) => {
        const date = obj.date;
        if (!labsData[date]) labsData[date] = {};

        labsData[date] = {
          ...labsData[date],
          Date: date,
          TotalLabsOrderedCovid: obj.TotalLabsOrdered,
          TotalLabsCompletedCovid: obj.TotalLabsCompleted,
          TotalTestedPositiveCovid: obj.TotalTestedPositive,
          TotalPositiveRateCovid: obj.PositiveRate,
        };
      });

      covidFinalData.raceDist.forEach((obj) => {
        const date = obj.date;
        if (!labsData[date]) labsData[date] = {};

        labsData[date] = {
          ...labsData[date],
          Date: date,
          AmericanIndianAlaskaNativeCovid: obj.AmericanIndianAlaskaNative,
          AsianCovid: obj.Asian,
          BlackAfricanAmericanCovid: obj.BlackAfricanAmerican,
          HispanicLatinoCovid: obj.HispanicLatino,
          NativeHawaiianOrOtherPacificIslanderCovid:
            obj.NativeHawaiianOrOtherPacificIslander,
          NonHispanicLatinoCovid: obj.NonHispanicLatino,
          otherCovid: obj.other,
          whiteCovid: obj.white,
          UnknownsCovid: obj.Unknown,
          NotReportedCovid: obj.NotReported,
          IncompleteCovid: obj.Incomplete,
        };
      });
      covidFinalData.uniquePatientsTotals.forEach((obj) => {
        const date = obj.date;
        if (!labsData[date]) labsData[date] = {};

        labsData[date] = {
          ...labsData[date],
          Date: date,
          UniquePatientsLabsOrderedCovid: obj.TotalLabsOrdered,
          UniquePatientsLabsCompletedCovid: obj.TotalLabsCompleted,
          UniquePatientsTestedPositiveCovid: obj.TotalTestedPositive,
          UniquePatientsPositiveRateCovid: obj.PositiveRate,
        };
      });

      influenzaFinalData.labsTotals.forEach((obj) => {
        const date = obj.date;
        if (!labsData[date]) labsData[date] = {};

        labsData[date] = {
          ...labsData[date],
          Date: date,
          TotalLabsOrderedInfluenza: obj.TotalLabsOrdered,
          TotalLabsCompletedInfluenza: obj.TotalLabsCompleted,
          TotalTestedPositiveInfluenza: obj.TotalTestedPositive,
          TotalPositiveRateInfluenza: obj.PositiveRate,
        };
      });
      influenzaFinalData.raceDist.forEach((obj) => {
        const date = obj.date;
        if (!labsData[date]) labsData[date] = {};

        labsData[date] = {
          ...labsData[date],
          Date: date,
          AmericanIndianAlaskaNativeInfluenza: obj.AmericanIndianAlaskaNative,
          AsianInfluenza: obj.Asian,
          BlackAfricanAmericanInfluenza: obj.BlackAfricanAmerican,
          HispanicLatinoInfluenza: obj.HispanicLatino,
          NativeHawaiianOrOtherPacificIslanderInfluenza:
            obj.NativeHawaiianOrOtherPacificIslander,
          NonHispanicLatinoInfluenza: obj.NonHispanicLatino,
          otherInfluenza: obj.other,
          whiteInfluenza: obj.white,
          UnknownsInfluenza: obj.Unknown,
          NotReportedInfluenza: obj.NotReported,
          IncompleteInfluenza: obj.Incomplete,
        };
      });
      influenzaFinalData.uniquePatientsTotals.forEach((obj) => {
        const date = obj.date;
        if (!labsData[date]) labsData[date] = {};

        labsData[date] = {
          ...labsData[date],
          Date: date,
          UniquePatientsLabsOrderedInfluenza: obj.TotalLabsOrdered,
          UniquePatientsLabsCompletedInfluenza: obj.TotalLabsCompleted,
          UniquePatientsTestedPositiveInfluenza: obj.TotalTestedPositive,
          UniquePatientsPositiveRateInfluenza: obj.PositiveRate,
        };
      });
      // console.log(labsData);
      setLabsData(Object.values(labsData));
      setLastOneWeekData(Object.values(labsData));

      setLoading(false);
      setButtonLoading(false);
    } catch (e) {
      setLoading(false);
      setShowError(true);
      setButtonLoading(false);
      console.log(e);
    }
  };
  const groupBy = (objectArray, property) => {
    return objectArray.reduce((acc, obj) => {
      const key = obj[property];
      if (!acc[key]) {
        acc[key] = [];
      }
      // Add object to list for given key's value
      acc[key].push(obj);
      return acc;
    }, {});
  };

  const updateTime = () => {
    var current = new Date();
    return current.toLocaleString();
  };

  const handleGroupAuth = (str) => {
    return userInfo && userInfo.indexOf(str) !== -1;
  };

  useEffect(() => {
    getLabsData();
    document.addEventListener("click", hideOnClickOutside, true);
    const interval = setInterval(() => {
      getDatesFromLabs();
      getLabsData();
      updateTime();
    }, 15000000);

    return () => clearInterval(interval);
  }, []);

  const refreshData = (e) => {
    e.preventDefault();
    setButtonLoading(true);
    getDatesFromLabs();
    getLabsData();
    updateTime();
  };
  const getDatesFromLabs = () => {
    // console.log(range[0].endDate);
    // console.log(range[0].startDate);
    // console.log(startTime, endTime, "old");
    const start =
      Math.round(new Date(range[0].startDate).getTime() / 1000) - 18000;
    console.log(start);
    const end = Math.round(new Date(range[0].endDate).getTime() / 1000);
    const labsFiltered = labsData.filter((obj) => {
      return (
        parseInt(obj.Date) >= parseInt(start) &&
        parseInt(obj.Date) <= parseInt(end)
      );
    });
    setLastOneWeekData(labsFiltered);
  };
  const hideOnClickOutside = (e) => {
    // console.log(refOne.current)
    // console.log(e.target)
    if (refOne.current && !refOne.current.contains(e.target)) {
      setOpen(false);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    sessionStorage.setItem("tabValue", newValue);
  };

  const handleOpenDisclaimer = () => {
    setOpenDisclaimer(true);
  };
  const handleClose = () => {
    setOpenDisclaimer(false);
  };
  if (handleGroupAuth("Nebraska Labs Dashboard Users")) {
    return (
      <div>
        {isMobileDevice ? (
          <div className="mt-3 pt-5">
            <ListItem>
              <ListItemIcon>
                <ScienceIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                <div className="d-inline">
                  <span style={{ fontWeight: "bold" }}> Labs Dashboard</span>
                  <span className="livebadge pulsate ml-2">NRT</span>{" "}
                  <div style={{ fontSize: "small" }}>
                    {" "}
                    Last Updated : {updateTime()}
                  </div>
                  <LoadingButton
                    className="mt-2"
                    size="small"
                    onClick={(e) => refreshData(e)}
                    loading={buttonLoading}
                    loadingPosition="end"
                    variant="outlined"
                    endIcon={<Refresh />}
                  >
                    {" "}
                    Refresh
                  </LoadingButton>
                </div>
                <Button
                  variant="outlined"
                  endIcon={<InfoRounded />}
                  size="small"
                  onClick={handleOpenDisclaimer}
                >
                  Disclaimer
                </Button>
              </ListItemText>
            </ListItem>

            <div>
              <Dialog
                open={openDisclaimer}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
              >
                <DialogTitle id="responsive-dialog-title">
                  Disclaimer
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    <Typography variant="body2">
                      Real-time lab data obtained from facilities that have
                      integrated with lab hub and CyncHealth. Real-time lab data
                      also obtained from HIE data which includes data from
                      CyncHealth participants with executed Participant
                      Agreements and Business Associate Agreements. Lab data
                      from the attached facilities is currently available in
                      HIE.
                      <br /> <br />
                      Authorized users agree information contained within the
                      CyncHealth's Dashboards is confidential and agree to not
                      disseminate such information contained therein.{" "}
                    </Typography>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button autoFocus onClick={handleClose}>
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </div>
        ) : (
          <div className="mt-5 pt-4">
            <ListItem className={classes.root}>
              <ListItemIcon>
                <ScienceIcon fontSize="medium" className={classes.icon} />
              </ListItemIcon>
              <ListItemText disableTypography>
                <span className={classes.text}> Labs Dashboard</span>
                <span className="livebadge pulsate ml-2">NRT</span>{" "}
                <div className="d-inline">
                  <span className=" ml-3"> Last Updated : {updateTime()}</span>
                  <LoadingButton
                    style={{ color: "#15727C" }}
                    className=" ml-2"
                    size="small"
                    onClick={(e) => refreshData(e)}
                    loading={buttonLoading}
                    loadingPosition="end"
                    variant="outlined"
                    endIcon={<Refresh />}
                  >
                    {" "}
                    Refresh
                  </LoadingButton>
                </div>
                <Button
                  className=" ml-2"
                  style={{ color: "#15727C" }}
                  size="small"
                  variant="outlined"
                  endIcon={<InfoRounded />}
                  onClick={handleOpenDisclaimer}
                >
                  Disclaimer
                </Button>
              </ListItemText>
            </ListItem>
            <div>
              <Dialog
                open={openDisclaimer}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
              >
                <DialogTitle id="responsive-dialog-title">
                  Disclaimer
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    <Typography variant="body2">
                      Real-time lab data obtained from facilities that have
                      integrated with lab hub and CyncHealth. Real-time lab data
                      also obtained from HIE data which includes data from
                      CyncHealth participants with executed Participant
                      Agreements and Business Associate Agreements. Lab data
                      from the attached facilities is currently available in
                      HIE.
                      <br /> <br />
                      Authorized users agree information contained within the
                      CyncHealth's Dashboards is confidential and agree to not
                      disseminate such information contained therein.{" "}
                    </Typography>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button autoFocus onClick={handleClose}>
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </div>
        )}
        <Paper elevation={1} style={{ padding: "2px", height: "auto" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              className={classes.tabs}
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              textColor="inherit"
              variant="scrollable"
            >
              <Tab
                className="tabs"
                label="Overview"
                sx={{
                  borderRight: (theme) => `1px solid ${theme.palette.divider}`,
                }}
                {...a11yProps(0)}
              />
              <Tab
                className="tabs"
                label="Demographics"
                sx={{
                  borderRight: (theme) => `1px solid ${theme.palette.divider}`,
                }}
                {...a11yProps(1)}
              />
            </Tabs>
          </Box>

          <Paper
            className="sticky-thc"
            style={{
              padding: "12px",
              marginBottom: "5px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs>
                <span>Date Range : </span>
                <TextField
                  style={{ width: "300px" }}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <DateRange />
                      </InputAdornment>
                    ),
                  }}
                  size="small"
                  value={`${format(
                    range[0].startDate,
                    "MM/dd/yyyy"
                  )} to ${format(range[0].endDate, "MM/dd/yyyy")}`}
                  onClick={() => setOpen((open) => !open)}
                />
                <Button
                  style={{ margin: 5, color: "#15727C" }}
                  variant="outlined"
                  size="small"
                  onClick={getDatesFromLabs}
                >
                  {" "}
                  Apply
                </Button>
              </Grid>

              <Grid item xs>
                <Multiselect
                  className="dropdown_customized_labs"
                  data={labsFilterOptions}
                  value={dataTypeFilter}
                  onChange={setDataTypeFilter}
                />
              </Grid>
            </Grid>
          </Paper>
          <div ref={refOne}>
            {open && (
              <DateRangePicker
                onChange={(item) => setRange([item.selection])}
                editableDateInputs={true}
                moveRangeOnFirstSelection={false}
                minDate={new Date("10/20/2022")}
                inputRanges={[]}
                rangeColors={["#2DA7B1", "#3ecf8e", "#fed14c"]}
                staticRanges={[
                  {
                    label: "This Week",

                    range: () => ({
                      startDate: defineds.startOfWeek,
                      endDate: defineds.endOfWeek,
                    }),
                    isSelected() {
                      return false;
                    },
                  },
                  {
                    label: "Last Week",
                    range: () => ({
                      startDate: defineds.startOfLastWeek,
                      endDate: defineds.endOfLastWeek,
                    }),
                    isSelected() {
                      return false;
                    },
                  },
                  {
                    label: "This Month",
                    range: () => ({
                      startDate: defineds.startOfMonth,
                      endDate: defineds.endOfMonth,
                    }),
                    isSelected() {
                      return false;
                    },
                  },
                  {
                    label: "Last Month",
                    range: () => ({
                      startDate: defineds.startOfLastMonth,
                      endDate: defineds.endOfLastMonth,
                    }),
                    isSelected() {
                      return false;
                    },
                  },
                  {
                    label: "All Time",
                    range: () => ({
                      startDate: defineds.startOfTheData,
                      endDate: defineds.endOfToday,
                    }),
                    isSelected() {
                      return false;
                    },
                  },
                ]}
                maxDate={new Date()}
                ranges={range}
                months={1}
                showDateDisplay={false}
                direction="vertical"
                className="calendarElement"
              />
            )}
          </div>
          <TabPanel value={value} index={0}>
            {showError === true ? (
              <Paper elevation={2} style={{ padding: "50px" }}>
                <Typography>
                  We are having trouble in loading data! Please try again after
                  some time.
                </Typography>
                <SyncProblemOutlined
                  style={{ width: "200px", height: "auto" }}
                />
              </Paper>
            ) : (
              <Paper
                elevation={0}
                style={{ padding: "8px", marginBottom: "10px" }}
              >
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  {loading ? (
                    <LinearProgress />
                  ) : (
                    <TotalLabsSummary
                      data={lastOneWeekData}
                      dataType={dataTypeFilter}
                    />
                  )}

                  {loading ? (
                    <LinearProgress />
                  ) : (
                    <TotalUniquePatsSummary
                      data={lastOneWeekData}
                      dataType={dataTypeFilter}
                      tab="overview"
                    />
                  )}
                </Box>
                {loading ? (
                  <LinearProgress />
                ) : (
                  <MyFirstGrid
                    data={lastOneWeekData}
                    dataType={dataTypeFilter || []}
                  />
                )}
              </Paper>
            )}
          </TabPanel>
          <TabPanel value={value} index={1}>
            {showError === true ? (
              <Paper elevation={2} style={{ padding: "50px" }}>
                <Typography>
                  We are having trouble in loading data! Please try again after
                  some time.
                </Typography>
                <SyncProblemOutlined
                  style={{ width: "200px", height: "auto" }}
                />
              </Paper>
            ) : (
              <Paper
                elevation={0}
                style={{ padding: "8px", marginBottom: "10px" }}
              >
                <Box>
                  {loading ? (
                    <LinearProgress />
                  ) : (
                    <TotalUniquePatsSummary
                      style={{ margin: "10px" }}
                      data={lastOneWeekData}
                      dataType={dataTypeFilter}
                      tab="demographic"
                    />
                  )}
                </Box>
                {loading ? (
                  <LinearProgress />
                ) : (
                  <MySecondGrid
                    data={lastOneWeekData}
                    dataType={dataTypeFilter}
                  />
                )}
              </Paper>
            )}
          </TabPanel>
        </Paper>
        <div className="alert alert-light mt-4" role="alert">
          Disclaimer: Disclosure of identifiable health information is made
          pursuant to IOWA Executive Order No. 20-15 and the federal
          <a
            href="https://www.hhs.gov/sites/default/files/notification-enforcement-discretion-hipaa.pdf?language=es"
            target="_blank"
          >
            HHS Notification of Enforcement Discretion under HIPAA{" "}
          </a>
          , as such use is made to individuals who are in a position to prevent
          and lessen a serious and imminent threat to the health and safety of a
          person or the public.
          <br /> Unknown records not included in the calculation of Age and
          Gender, Race, Ethnicity graphs
          <br></br> <br></br>
          NRT - Near real-time with a delay of 5-15 minutes.
        </div>
      </div>
    );
  } else {
    return <NotFound />;
  }
}
