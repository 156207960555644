import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import OverviewTable from "./OverviewTable";
import { makeStyles } from "@material-ui/core/styles";
import AdultBedsTable from "./AdultBedsTable";
import PediatricBedsTable from "./PediatricBedsTable";
import SpecialtyBedsTable from "./MentalHealthBedsTable";
import MomAndBabyBedsTable from "./MomAndBabyBedsTable";
import "./LiveBedCapacity.css";
import TotalAvailableBeds from "./TotalAvailableBeds";
import PsychiatryBedsTable from "./PsychiatryBedsTable";
import MentalHealthBedsTables from "./MentalHealthBedsTable";
import MentalHealthBedsTable from "./MentalHealthBedsTable";
import { Icon } from "@material-ui/core";
import PsychiatricBedsGroup from "./PsychiatricBedsGroup";
import { useMediaQuery } from "react-responsive";
import HospitalsMap from "./HospitalsMap";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tabpanel-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const useStyles = makeStyles({
  root: {
    height: "30px",
    color: "#2F5E8C",
  },
  text: {
    marginLeft: "-20px",
    fontStyle: "bold",
  },
  icon: {
    color: "#2F5E8C",
    marginLeft: "-10px",
  },
  tabs: {
    "& .MuiTabs-indicator": {
      display: "none",
    },
    "& .Mui-selected": {
      backgroundColor: "#2F5E8C",

      color: "white",
      boxShadow: "4px 4px 20px 0px black",
    },
  },
});

function a11yProps(index) {
  return {
    id: `full-width-tabpanel-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function BedCapacityGroup() {
  const [value, setValue] = React.useState(
    parseInt(sessionStorage.getItem("tabValue")) || 0
  );
  const [data, setData] = React.useState([]);
  const [totalOverviewData, setTotalOverviewData] = React.useState([]);
  const [totalAdultData, setTotalAdultData] = React.useState([]);
  const [totalPediatriData, setTotalPediatricData] = React.useState([]);
  const [totalMentalHealthData, setTotalmentalhealthData] = React.useState([]);
  const [totalPsychiatryData, setTotalPsychiatryData] = React.useState([]);
  const [totalMomAndBabyData, setTotalMomAndBabyData] = React.useState([]);
  const classes = useStyles();
  const isMobileDevice = useMediaQuery({
    query: "(max-device-width: 480px)",
  });
  const isTabDevice = useMediaQuery({
    query: "(max-device-width: 1150px) ",
  });
  useEffect(() => {
    // GET request using fetch inside useEffect React hook
    fetch("/IA_Bed_Occupancy.json")
      .then((response) => response.json())
      .then((data) => {
        // setValue(sessionStorage.getItem("tabValue"))
        setData(data[0].occupancyByHospital);

        setTotalOverviewData(data[0].totals[0].TotalOverviewData);
        setTotalAdultData(data[0].totals[1].TotalAdultsData);
        setTotalPediatricData(data[0].totals[2].TotalPediatricData);
        setTotalmentalhealthData(data[0].totals[3].TotalMentalHealthData);
        setTotalPsychiatryData(data[0].totals[4].TotalPsychiatryData);
        setTotalMomAndBabyData(data[0].totals[5].TotalMomAndBabyData);
      });

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    sessionStorage.setItem("tabValue", newValue);
  };

  return (
    <div>
      <Box sx={{ margin: "-7px", borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          className={classes.tabs}
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          textColor="inherit"
          variant="scrollable"
        >
          <Tab
            aria-label="basic tab 1"
            id="basic-tab-1"
            sx={{
              borderRight: (theme) => `1px solid ${theme.palette.divider}`,
            }}
            label="Overview"
            {...a11yProps(0)}
          />

          <Tab
            aria-label="basic tab 2"
            id="basic-tab-2"
            sx={{
              borderRight: (theme) => `1px solid ${theme.palette.divider}`,
            }}
            label="Adult"
            {...a11yProps(1)}
          />
          <Tab
            id="basic-tab-3"
            aria-label="basic tab 3"
            sx={{
              borderRight: (theme) => `1px solid ${theme.palette.divider}`,
            }}
            label="Pediatric"
            {...a11yProps(2)}
          />
          <Tab
            id="basic-tab-4"
            sx={{
              borderRight: (theme) => `1px solid ${theme.palette.divider}`,
            }}
            label="Substance Abuse"
            {...a11yProps(3)}
          />
          <Tab
            id="basic-tab-5"
            sx={{
              borderRight: (theme) => `1px solid ${theme.palette.divider}`,
            }}
            label="Psychiatry"
            {...a11yProps(4)}
          />
          <Tab id="basic-tab-6" label="Mom and Baby" {...a11yProps(5)} />
        </Tabs>
      </Box>
      <Box sx={{ width: "100%" }}>
        {isMobileDevice || isTabDevice ? (
          <div className="legend-box">
            <div>
              <strong> Legend : </strong>
              <div>
                <span id="circle2"> &nbsp; &nbsp; </span> &nbsp;Greater than or
                equal to 80%
              </div>
              <div>
                <span id="circle3"> &nbsp; &nbsp; </span> &nbsp;between 60% to
                79%
              </div>
              <div>
                <span id="circle4"> &nbsp; &nbsp; </span> &nbsp;less than 60%
              </div>
            </div>
          </div>
        ) : (
          <div className="legend-box">
            <div className="legend-labels">
              <strong> Legend : </strong>
              <div>
                <span id="circle2"> &nbsp; &nbsp; </span> &nbsp;Greater than or
                equal to 80%
              </div>
              <div>
                <span id="circle3"> &nbsp; &nbsp; </span> &nbsp;between 60% to
                79%
              </div>
              <div>
                <span id="circle4"> &nbsp; &nbsp; </span> &nbsp;less than 60%
              </div>
            </div>
          </div>
        )}

        <TabPanel value={value} index={0} id="basic-tab-1">
          <TotalAvailableBeds data={totalOverviewData} />
          <OverviewTable data={data} />
        </TabPanel>
        <TabPanel value={value} index={1} id="basic-tab-2">
          <TotalAvailableBeds data={totalAdultData} />
          <AdultBedsTable data={data} />
        </TabPanel>
        <TabPanel value={value} index={2} id="basic-tab-3">
          <TotalAvailableBeds data={totalPediatriData} />
          <PediatricBedsTable data={data} />
        </TabPanel>
        <TabPanel value={value} index={3} id="basic-tab-4">
          <TotalAvailableBeds data={totalMentalHealthData} />
          <MentalHealthBedsTable data={data} />
        </TabPanel>
        <TabPanel value={value} index={4} id="basic-tab-5">
          <TotalAvailableBeds data={totalPsychiatryData} />
          <PsychiatricBedsGroup />
        </TabPanel>
        <TabPanel value={value} index={5} id="basic-tab-6">
          <TotalAvailableBeds data={totalMomAndBabyData} />
          <MomAndBabyBedsTable data={data} />
        </TabPanel>
      </Box>
    </div>
  );
}
