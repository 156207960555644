import React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import ScienceIcon from "@mui/icons-material/Science";
import DoneIcon from "@mui/icons-material/Done";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import TimelineIcon from "@mui/icons-material/Timeline";

const useStyles = makeStyles({
  root: {
    height: "30px",
    color: "black",
  },
  text: {
    marginLeft: "-20px",
    color: "#31608D",
    fontStyle: "bold",
  },
  icon: {
    color: "#31608D",
    marginLeft: "-10px",
    fontSize: "25px",
  },
  iconBox: {
    color: "#31608D",
    fontSize: "large",
    border: " 0px solid rgb(13,202,240)",
    borderRadius: "100%",
    backgroundColor: "gray",
    margin: "3px",
    padding: "5px",
  },
});

function TotalAdtData({ data }) {
  const classes = useStyles();
  console.log(data)
  return (
    <div>
      {/* Total beds card */}
      <h6 className="card-header bg-light">
        <ListItem className={classes.root}>
          <ListItemIcon>
            <ScienceIcon className={classes.icon} />
          </ListItemIcon>
          <ListItemText disableTypography className={classes.text}>
            OVERALL ADMITS AND DISCHARGES{" "}
          </ListItemText>
        </ListItem>{" "}
      </h6>{" "}
      <div className="row pt-4 pb-4">
        <div className="col-sm-3">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title font-weight-bold">
                {" "}
                {data.ipAdmits}
              </h2>
              <p className="card-text text-muted">IP Admits</p>
            </div>
          </div>
        </div>

        <div className="col-sm-3">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title font-weight-bold">
                {" "}
                {data.ipDischarges}
              </h2>
              <p className="card-text text-muted">IP Discharges</p>
            </div>
          </div>
        </div>

        <div className="col-sm-3">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title font-weight-bold">
                {" "}
                {data.edAdmits}
              </h2>
              <p className="card-text text-muted">ED Admits</p>
            </div>
          </div>
        </div>

        <div className="col-sm-3">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title font-weight-bold">
                {" "}
                {data.edDischarges}
              </h2>
              <p className="card-text text-muted">ED Discharges</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TotalAdtData;
