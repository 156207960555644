import React, { useMemo, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useOktaAuth } from "@okta/okta-react";
import AppUsers from "./AppUsers";
import NotFound from "../Alerts/NotFound";

const useStyles = makeStyles({
  root: {
    height: "30px",
    color: "rgb(8,87,97)",
  },
  text: {
    marginLeft: "-20px",
    fontWeight: "bold",
    fontSize: "30px",
  },
  icon: {
    color: "rgb(8,87,97)",
    marginLeft: "-10px",
  },
});

export default function AdminAuth() {
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState("");
  const classes = useStyles();

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      oktaAuth.getUser().then((info) => {
        setUserInfo(info.groups);
      });
    }
  }, [authState, oktaAuth]);

  if (userInfo.indexOf("IDPH_IA_Dashboard_Admins") >= 0) {
    return <AppUsers />;
  } else {
    return <NotFound/>;
  }
}
