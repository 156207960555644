export const iowaCounties = [
  "Audubon",
  "Emmet",
  "Lyon",
  "Polk",
  "Cass",
  "Page",
  "Floyd",
  "Plymouth",
  "Sioux",
  "Wright",
  "Jefferson",
  "Dickinson",
  "Shelby",
  "Osceola",
  "Wapello",
  "Marion",
  "Howard",
  "Clay",
  "Johnson",
  "Van Buren",
  "Bremer",
  "Winneshiek",
];

export const overviewColumnNames = [
  "Hospital Name",
  "Trauma Levels",
  "Hospital Type",
  "NMDS",
  "Hospital City",
  "Hospital County",
  "Hospital Zip",
  "AHA Number",
  "RMCC",
  "HCC",
  "Total Licensed Beds",
  "Total Occupied Beds",
  "Total Beds Available",
  "Percentage Occupied",
  "Total ICU Beds",
  "Total ICU Beds Occupied",
  "Total ICU Beds Available",
  "Percentage Occupied ICU",
  "Total ER Beds",
  "Total ER Beds Occupied",
  "Total ER Beds Available",
  "Percentage Occupied ER",
];

export const adultTabColumnNames = [
  "Hospital Name",
  "Hospital Type",
  "NMDS",
  "Hospital City",
  "Hospital County",
  "Hospital Zip",
  "AHA Number",
  "RMCC",
  "HCC",
  "Total ICU Licensed Beds",
  "Total ICU Occupied Beds",
  "Total ICU Beds Available",
  "ICU Percentage Occupied",
  "Total Licensed Beds",
  "Total Occupied Beds",
  "Total Beds Available",
  "Percentage Occupied",
];

export const pediatricTabColumnNames = [
  "Hospital Name",
  "Hospital Type",
  "NMDS",
  "Hospital City",
  "Hospital County",
  "Hospital Zip",
  "AHA Number",
  "RMCC",
  "HCC",
  "Total ICU Licensed Beds",
  "Total ICU Occupied Beds",
  "Total ICU Beds Available",
  "ICU Percentage Occupied",
  "Total Licensed Beds",
  "Total Occupied Beds",
  "Total Beds Available",
  "Percentage Occupied",
  "Total Neo Licensed Beds",
  "Total Neo Occupied Beds",
  "Total Neo Beds Available",
  "Neo Percentage Occupied",
];
export const mentalHealthTabColumnNames = [
  "Hospital Name",
  "Hospital Type",
  "NMDS",
  "Hospital City",
  "Hospital County",
  "Hospital Zip",
  "AHA Number",
  "RMCC",
  "HCC",
  "Total Licensed Beds",
  "Total Occupied Beds",
  "Total Beds Available",
  "Percentage Occupied",
];

export const psychiatryAllBedsTabColumnNames = [
  "Hospital Name",
  "Hospital Type",
  "NMDS",
  "Hospital City",
  "Hospital County",
  "Hospital Zip",
  "AHA Number",
  "RMCC",
  "HCC",
  "Total Licensed Beds",
  "Total Occupied Beds",
  "Total Beds Available",
  "Percentage Occupied",
];

export const psychiatryBedsTabColumnNames = [
  "Hospital Name",
  "Hospital Type",
  "NMDS",
  "Hospital City",
  "Hospital County",
  "Hospital Zip",
  "AHA Number",
  "RMCC",
  "HCC",
  "Total Child Licensed Beds",
  "Total Child Occupied Beds",
  "Total Child Beds Available",
  "Child Percentage Occupied",
  "Total Adult Licensed Beds",
  "Total Adult Occupied Beds",
  "Total Adult Beds Available",
  "Adult Percentage Occupied",
  "Total Older Licensed Beds",
  "Total Older Occupied Beds",
  "Total Older Beds Available",
  "Older Percentage Occupied",
];
export const momBabyTabColumnNames = [
  "Hospital Name",
  "Hospital Type",
  "NMDS",
  "Hospital City",
  "Hospital County",
  "Hospital Zip",
  "AHA Number",
  "RMCC",
  "HCC",
  "Total L&D Licensed Beds",
  "Total L&D Occupied Beds",
  "Total L&D Beds Available",
  "L&D Percentage Occupied",
  "Total Licensed Beds",
  "Total Occupied Beds",
  "Total Beds Available",
  "Percentage Occupied",
];
export const rmccOptions = [
  "Region 1",
  "Region 2",
  "Region 3",
  "Region 4",
  "Region 5",
];

export const hccOptions = ["1A", "1C", "2", "3", "4", "5", "6", "7"];
