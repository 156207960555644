import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useEffect } from "react";

const LabsEthnicityDist = ({ data, dataType }) => {
  const [hispanicLatino, setHispanicLatino] = React.useState("");
  const [nonHispanicLatino, setNonHispanicLatino] = React.useState("");
  const [incomplete, setIncomplete] = React.useState("");
  const [notReported, setNotReported] = React.useState("");

  const calculate = () => {
    const calculateSumOfHispanicLatino = data.reduce((accumulator, object) => {
      let initNumber = parseFloat(accumulator);
      dataType.forEach(
        (type) => (initNumber += parseFloat(object[`HispanicLatino${type}`]))
      );

      return initNumber;
    }, 0);
    setHispanicLatino(calculateSumOfHispanicLatino);

    const calculateSumOfNonHispanicLatino = data.reduce(
      (accumulator, object) => {
        let initNumber = parseFloat(accumulator);
        dataType.forEach(
          (type) =>
            (initNumber += parseFloat(object[`NonHispanicLatino${type}`]))
        );
        return initNumber;
      },
      0
    );
    setNonHispanicLatino(calculateSumOfNonHispanicLatino);

    const calculateSumOfIncomplete = data.reduce(
      (accumulator, object) => {
        let initNumber = parseFloat(accumulator);
        dataType.forEach(
          (type) => {object[`Incomplete${type}`] ? (initNumber += parseFloat(object[`Incomplete${type}`])) : ""}
            
        );
        return initNumber;
      },
      0
    );
    setIncomplete(calculateSumOfIncomplete);

    const calculateSumOfNotReported = data.reduce(
      (accumulator, object) => {
        let initNumber = parseFloat(accumulator);
        dataType.forEach(
          (type) =>{ object[`NotReported${type}`] ?
            (initNumber += parseFloat(object[`NotReported${type}`])):""}
        );
        return initNumber;
      },
      0
    );
    setNotReported(calculateSumOfNotReported);
  };
const totalPopulation = hispanicLatino + nonHispanicLatino + incomplete + notReported;

  useEffect(() => {
    calculate();
  }, [data, dataType]);

  const newData = [
    ["Hispanic Latino", parseFloat(((hispanicLatino/totalPopulation)*100).toFixed(2))],
    ["Non-Hispanic Latino", parseFloat(((nonHispanicLatino/totalPopulation)*100).toFixed(2))],
    ["Incomplete", parseFloat(((incomplete/totalPopulation)*100).toFixed(2))],
    ["Not Reported", parseFloat(((notReported/totalPopulation)*100).toFixed(2))],
  ];

  const options = {
    chart: {
      type: "column",
      zoomType: "xy"
    },
    title: {
      text: "Ethnicity Distribution of Positive Tests",
      style: {
        fontSize: '14px'
      },
    },

    credits: {
      enabled: false,
    },
    exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          menuItems: ["viewFullscreen"],
        },
      },
    },
    xAxis: {
      type: "category",
      labels: {
        style: {
          fontSize: "13px",
          fontFamily: "Verdana, sans-serif",
        },
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "Percentage",
      },
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      valueSuffix: "%",
    },
    series: [
      {
        name: "Percentage",
        data: newData || "",
        color: "rgb(52, 181, 191)",
      },
    ],
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options}  allowChartUpdate={true}
        immutable={true} />
    </div>
  );
};
export default LabsEthnicityDist;
