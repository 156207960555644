import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { makeStyles } from "@material-ui/core/styles";
import "./LiveBedCapacity.css";
import PsychiatryBedsTable from "./PsychiatryBedsTable";
import "./Table.css";
import PsychiatryFemaleBedsTable from "./PsychiatricFemaleBedsTable";
import PsychiatryMaleBedsTable from "./PsychiatricMaleBedsTable";
import PsychiatryAllBedsTable from "./PsychiatryAllBedsTable";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tabpanel-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const useStyles = makeStyles({
  root: {
    height: "30px",
    color: "#2F5E8C",
  },
  text: {
    marginLeft: "-20px",
    fontStyle: "bold",
  },
  icon: {
    color: "#2F5E8C",
    marginLeft: "-10px",
  },
  tabs: {
    "& .MuiTabs-indicator": {
      display: "none",
    },
    "& .Mui-selected": {
      backgroundColor: "#2F5E8C",

      color: "white",
      boxShadow: "4px 4px 20px 0px grey",
    },
  },
});

function a11yProps(index) {
  return {
    id: `full-width-tabpanel-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function PsychiatricBedsGroup() {
  const [value, setValue] = React.useState(
    parseInt(sessionStorage.getItem("tabValuePsych")) || 0
  );
  const [data, setData] = React.useState([]);
  const classes = useStyles();

  useEffect(() => {
    // GET request using fetch inside useEffect React hook
    fetch("/IA_Bed_Occupancy.json")
      .then((response) => response.json())
      .then((data) => {
        // setValue(sessionStorage.getItem("tabValue"))
        setData(data[0].occupancyByHospital);
      });

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    sessionStorage.setItem("tabValuePsych", newValue);
  };

  return (
    <div className="occupancyBox1">
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          className={classes.tabs}
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          textColor="inherit"
          variant="scrollable"
        >
          <Tab
            sx={{
              borderRight: (theme) => `1px solid ${theme.palette.divider}`,
            }}
            label="All Psychiatry"
            {...a11yProps(0)}
          />

          <Tab
            sx={{
              borderRight: (theme) => `1px solid ${theme.palette.divider}`,
            }}
            label="Psychiatry Either"
            {...a11yProps(1)}
          />

          <Tab
            sx={{
              borderRight: (theme) => `1px solid ${theme.palette.divider}`,
            }}
            label="Male"
            {...a11yProps(2)}
          />
          <Tab
            sx={{
              borderRight: (theme) => `1px solid ${theme.palette.divider}`,
            }}
            label="Female"
            {...a11yProps(3)}
          />
        </Tabs>
      </Box>
      <Box sx={{ width: "100%" }}>
        <TabPanel value={value} index={0}>
          <PsychiatryAllBedsTable data={data} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <PsychiatryBedsTable data={data} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <PsychiatryMaleBedsTable data={data} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <PsychiatryFemaleBedsTable data={data} />
        </TabPanel>
      </Box>
    </div>
  );
}
