import React, { useMemo, useState, useEffect } from "react";
import TotalAvailableBeds from "./TotalAvailableBeds";
import HotelIcon from "@material-ui/icons/Hotel";
import Paper from "@mui/material/Paper";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { useOktaAuth } from "@okta/okta-react";
import LinearProgress from "@mui/material/LinearProgress";
import NotFound from "../../Alerts/NotFound";
import axios from "axios";
import { useMediaQuery } from "react-responsive";

import { Button, List } from "@mui/material";
import BedCapacityGroup from "./BedCapacityGroup";
import InfoIcon from "@mui/icons-material/Info";
import OverviewTable from "./OverviewTable";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const useStyles = makeStyles({
  root: {
    height: "30px",
    color: "#2F5E8C",
  },
  text: {
    marginLeft: "-20px",
    fontWeight: "bold",
    fontSize: "30px",
  },
  icon: {
    color: "#2F5E8C",
  },
});

export default function LiveBedCapacity() {
  const [data, setData] = useState([]);
  const [bedsData, setBedsData] = useState([]);
  const { authState, oktaAuth } = useOktaAuth();
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState("");
  const classes = useStyles();
  const accessTokenResponse = authState.accessToken;
  const bearerToken = accessTokenResponse?.accessToken;
  const isMobileDevice = useMediaQuery({
    query: "(max-device-width: 480px)",
  });

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      oktaAuth.getUser().then((info) => {
        setUserInfo(info.groups);
      });
    }
  }, [authState, oktaAuth]);
  // const getData = async () => {
  //   setLoading(true);
  //   try {
  //     const url = process.env.REACT_APP_BASE_API + "/bedlivecapacity";
  //     const result = await axios
  //       .get(url, {
  //         headers: {
  //           Authorization: bearerToken,
  //           "Content-Type": "application/json",
  //         },
  //       })
  //       .then((res) => {
  //         // setData(res.data.per_hospital_summary);
  //         setBedsData(res.data.total_hospital_summary);
  //         setLoading(false);
  //       });
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  const updateTime = () => {
    var current = new Date();
    return current.toLocaleString();
  };

  // useEffect(() => {
  //   getData();

  //   const interval = setInterval(() => {
  //     getData();
  //     updateTime();
  //   }, 1000000);

  //   return () => clearInterval(interval);
  // }, []);

  const handleGroupAuth = (str) => {
    return userInfo && userInfo.indexOf(str) !== -1;
  };

  if (handleGroupAuth("IDPH_IA_Bed_Occupancy_Users")) {
    return (
      <div>
        {isMobileDevice ? (
          <div className="mt-3 pt-5">
            <List>
            <ListItem>
              <ListItemIcon>
                <HotelIcon fontSize="meldium" className={classes.icon} />
              </ListItemIcon>

              <ListItemText>
                <div className="d-inline">
                  <span>Bed Capacity Dashboard</span>
                  <span className="livebadge pulsate ml-2">Live</span>{" "}
                  <div style={{ fontSize: "small" }}>
                    {" "}
                    Last Updated : {updateTime()}
                  </div>
                </div>
              </ListItemText>
            </ListItem>
            </List>
            <div className="alert alert-light m-3">
              Authorized users agree information contained within the
              CyncHealth's Dashboards is confidential and agree to not
              disseminate such information contained therein.
            </div>
          </div>
        ) : (
          <div className="mt-5 pt-4">
            <List>
            <ListItem className={classes.root}>
              <ListItemIcon>
                <HotelIcon fontSize="large" className={classes.icon} />
              </ListItemIcon>
              <ListItemText disableTypography>
                <span className={classes.text}>Bed Capacity Dashboard</span>
                <div className="d-inline">
                  <span className="livebadge pulsate ml-2">Live</span>{" "}
                  <span className=" ml-3"> Last Updated : {updateTime()}</span>
                </div>
              </ListItemText>
            </ListItem>
            </List>
            <div className="alert alert-light mt-3">
              Authorized users agree information contained within the
              CyncHealth's Dashboards is confidential and agree to not
              disseminate such information contained therein.
            </div>
          </div>
        )}

        <Paper elevation={2} style={{ padding: "8px", height: "auto" }}>
          {loading ? <LinearProgress /> : <BedCapacityGroup />}
        </Paper>

        <div className="alert alert-light mt-4" role="alert">
          Disclaimer : This Dashboard is provided as a service to Authorized
          Users pursuant to applicable Data Sharing and Participation Agreements
          and in compliance with all applicable laws. Users are restricted from
          the following activities pursuant to the Agreements: make this Service
          available to any other person, entity or business; copy,
          reverse-engineer, decompile, or disassemble the System or data
          contained herein; and modify or combine the System with any other
          software or services not provided or approved by CyncHealth.
          <br></br>
          <br></br>Data shown calculates beds occupied by total beds minus
          available beds. This near-real-time occupancy data comes from the
          state HIE (CyncHealth) and so only contains those facilities who are
          actively sharing data with CyncHealth . Bed types are obtained from
          the interface message and can only reflect the data as shared by the
          facility.
        </div>
      </div>
    );
  } else {
    return <NotFound/>;
  }
}
