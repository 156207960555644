import React, { Component } from "react";
import { Modal, Button, Form } from "react-bootstrap";

export default class UpdateUserProfile extends Component {
  constructor(props) {
    super(props);
  }

  handleSubmit(event) {
    event.preventDefault();
    const formBody = {
      profile: {
        firstName: event.target.firstName.value,
        lastName: event.target.lastName.value,
        mobilePhone: event.target.phone.value,
        login: event.target.loginid.value,
        user_id: event.target.userId.value,
      },
    };
    const updateProfileUrl = process.env.REACT_APP_BASE_API_ADMIN + "/editprofile";
    fetch( updateProfileUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: event.target.token.value,
      },
      body: JSON.stringify(formBody),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result);
        },
        (error) => {
          alert("failed");
        }
      );
  }

  render() {
    return (
      <div>
        <Modal {...this.props} centered size="sm">
          <Modal.Header closeButton>
            <Modal.Title>Edit Profile</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={this.handleSubmit}>
              <Form.Group className="mb-3" controlId="controlInput1">
                <Form.Control
                  type="token"
                  name="token"
                  placeholder="000101bj34545vj"
                  defaultValue={this.props.token}
                  disabled
                  hidden
                />
                <Form.Control
                  type="userid"
                  name="userId"
                  placeholder="000101bj34545vj"
                  defaultValue={this.props.userData.sub}
                  disabled
                  hidden
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="controlInput1">
                <Form.Label> First Name</Form.Label>
                <Form.Control
                  type="name"
                  name="firstName"
                  placeholder="Ex: John"
                  required
                  autoFocus
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="controlInput2">
                <Form.Label> Last Name</Form.Label>
                <Form.Control
                  type="name"
                  name="lastName"
                  placeholder="Ex: Doe"
                  autoFocus
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="controlInput3">
                <Form.Label> Login Id</Form.Label>
                <Form.Control
                  type="email"
                  name="loginid"
                  placeholder="Ex: johndoe@xyz.com"
                  autoFocus
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="controlInput4">
                <Form.Label> Phone</Form.Label>
                <Form.Control
                  type="phone"
                  name="phone"
                  placeholder="Ex: 9343453245"
                  autoFocus
                  required
                />
              </Form.Group>

              {/* <Form.Group className="mb-3" controlId="controlInput5">
              <Form.Label> Provider</Form.Label>
              <Form.Control
                type="provider"
                name="provider"
                placeholder="Ex: Patient, Doctor ...."
                autoFocus
                required
              />
            </Form.Group> */}
              <Button variant="success" type="submit">
                Save Changes
              </Button>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-danger" onClick={this.props.onHide}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
